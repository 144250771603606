import { ErrorMessage } from 'formik';
import { Form, InputGroup, Col } from 'react-bootstrap';
import { phoneMask } from '../Helpers/phoneHelpers';
import MaskedInput from 'react-text-mask';

function PhoneInput({ handleChange, handleBlur, values, touched, errors }) {
  return (
    <Form.Group as={Col} controlId="phone" className="mb-4">
      <Form.Label className="label">Phone</Form.Label>
      <InputGroup hasValidation>
        <MaskedInput
          mask={phoneMask}
          type="tel"
          name="phone"
          size="lg"
          onChange={handleChange}
          value={values.phone}
          isInvalid={touched.phone && !!errors.phone}
          onBlur={handleBlur}
          render={(ref, props) => <Form.Control {...props} ref={ref} />}
        />
        <ErrorMessage
          component="div"
          name="phone"
          className="invalid-feedback"
        />
      </InputGroup>
    </Form.Group>
  );
}

export default PhoneInput;
