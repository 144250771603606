import { Form, Button, Col, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';

function SearchInput({
  label,
  name,
  handleChange,
  values,
  handleClear,
  handleSubmit,
  hideSubmit = false,
}) {
  return (
    <Form.Group as={Col} lg="4" controlId={name} className="search-input mb-3">
      <Form.Label className="label fs-6">{label}</Form.Label>
      <InputGroup hasValidation>
        <Form.Control
          type="text"
          aria-describedby="inputGroupPrepend"
          name={name}
          onChange={handleChange}
          value={values[name]}
          size="lg"
          className="border-end-0"
        />
        <Button
          onClick={handleClear}
          variant="link"
          className={`btn-clear-search ${
            hideSubmit ? 'border-end' : ''
          }`}
        >
          <FontAwesomeIcon icon={faXmark} />
          <span className="sr-only">Clear search</span>
        </Button>
        {!hideSubmit && (
          <Button onClick={handleSubmit} type="submit">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
            <span className="sr-only">Search</span>
          </Button>
        )}
        <Form.Control.Feedback type="invalid" />
      </InputGroup>
    </Form.Group>
  );
}

export default SearchInput;
