const AUTH_LEVEL_ADMIN = 'admin';
const AUTH_LEVEL_USER = 'user';
const AUTH_COOKIE_NAME = 'olt_cookie';
const AUTH_COOKIE_EXPIRY = 7; // days

const MAX_FILE_SIZE = 5242880; // 5 mb
const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export {
  AUTH_LEVEL_ADMIN,
  AUTH_LEVEL_USER,
  AUTH_COOKIE_NAME,
  AUTH_COOKIE_EXPIRY,
  MAX_FILE_SIZE,
  SUPPORTED_IMAGE_FORMATS,
};
