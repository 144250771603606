import { Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function BackLinkTitle({ url, srText, title, noMargin }) {
  return (
    <Stack
      className={`col-auto ${noMargin ? '' : 'mb-5'}`}
      direction="horizontal"
    >
      <Link to={url} className="back-link">
        <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
        <span className="sr-only">{srText}</span>
      </Link>
      {title && <h1>{title}</h1>}
    </Stack>
  );
}

export default BackLinkTitle;
