import { useEffect, useState, useCallback } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { Form as FormikForm, Formik } from 'formik';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Table,
} from 'react-bootstrap';
import moment from 'moment';
import API from '../../Helpers/API';
import ReceiptService from '../../Helpers/ReceiptService';
import AlertErrors from '../AlertErrors';
import ListPagination from '../ListPagination';
import SearchInput from '../SearchInput';
import isLoggedIn from '../../Helpers/LoggedIn';
import { AUTH_LEVEL_ADMIN } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faMagnifyingGlass,
  faXmark,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import currency from 'currency.js';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import { catchApiResponseError } from '../../Helpers/catchApiResponseError';
import Spinner from '../Spinner';

function Receipts() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [receipts, setReceipts] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [searchField, setSearchField] = useState('');
  const [isAdmin] = useState(isLoggedIn() === AUTH_LEVEL_ADMIN);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [voided, setVoided] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const [showSpinner, setShowSpinner] = useState(false);

  const doReceiptSearch = useCallback(
    async (input, page, perPage, sDate, eDate, voided) => {
      try {
        setShowSpinner(true);
        const response = await new ReceiptService(new API()).getReceipts(
          input.receiptSearch,
          page,
          perPage,
          sDate ? moment(sDate).startOf('day').format() : null,
          eDate ? moment(eDate).endOf('day').format() : null,
          voided,
        );
        setReceipts(response.data);
        setFormSubmitted(true);
        response && setShowSpinner(false);
      } catch (err) {
        catchApiResponseError(err, setErrorMessages, setShowErrors);
        setShowSpinner(false);
      }
    },
    [],
  );

  const handleSubmit = (input) => {
    !input.receiptSearch &&
      !startDate &&
      !endDate &&
      !voided &&
      navigate('/receipts');

    const params = {
      search: input.receiptSearch,
      startDate: startDate ? moment(startDate).startOf('day').format() : null,
      endDate: endDate ? moment(endDate).endOf('day').format() : null,
      voided: voided ? 1 : false,
    };

    const queryString = Object.keys(params)
      .map((key) => params[key] && key + '=' + params[key])
      .filter(Boolean)
      .join('&');

    navigate(`/receipts?${queryString}`);
  };

  const clearSearch = () => {
    setSearchField('');
    searchParams.delete('search');
    setSearchParams(searchParams);
  };

  const clearDates = () => {
    searchParams.delete('startDate');
    searchParams.delete('endDate');
    setStartDate(null);
    setEndDate(null);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const search = searchParams.get('search');
    const page = searchParams.get('page');
    const perPage = searchParams.get('perPage');
    const sDate = searchParams.get('startDate');
    const eDate = searchParams.get('endDate');
    const voided = searchParams.get('voided');

    if (sDate) setStartDate(moment(sDate));
    if (eDate) setEndDate(moment(eDate));
    if (search) setSearchField(search);
    if (voided === '1') setVoided(true);

    doReceiptSearch(
      { receiptSearch: search },
      page,
      perPage,
      sDate,
      eDate,
      voided,
    );
  }, [searchParams, isAdmin, doReceiptSearch]);

  return (
    <>
      <Container className="mt-4">
        <Row className="align-items-baseline mb-4">
          <Col>
            <h1>Receipts</h1>
          </Col>
          <Col xs="auto">
            <Link to="/add-receipt" className="btn btn-primary">
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              New Receipt
            </Link>
          </Col>
        </Row>

        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={{ receiptSearch: searchField }}
        >
          {({
            handleSubmit,
            handleChange,
            handleReset,
            values,
            touched,
            errors,
          }) => (
            <>
              <FormikForm onSubmit={handleSubmit}>
                <Row>
                  <SearchInput
                    label="Search by receipt number or seller"
                    name="receiptSearch"
                    handleChange={handleChange}
                    values={values}
                    handleClear={() => {
                      handleReset();
                      clearSearch();
                    }}
                    handleSubmit={handleSubmit}
                    hideSubmit
                  />

                  <Form.Group as={Col} md="auto" className="mb-3">
                    <Form.Label className="label">Date Filter</Form.Label>
                    <InputGroup hasValidation className="full-width-datepicker">
                      <DateRangePicker
                        startDate={startDate}
                        startDateId="startDate"
                        endDate={endDate}
                        endDateId="endDate"
                        onDatesChange={handleDatesChange}
                        focusedInput={focusedInput}
                        minimumNights={0}
                        onFocusChange={(focusedInput) =>
                          setFocusedInput(focusedInput)
                        }
                        isOutsideRange={(day) =>
                          !isInclusivelyBeforeDay(day, moment())
                        }
                        numberOfMonths={1}
                        readOnly
                      />
                      <Button
                        onClick={clearDates}
                        variant="link"
                        className="btn-clear-search border-end"
                      >
                        <FontAwesomeIcon icon={faXmark} />
                        <span className="sr-only">Clear search</span>
                      </Button>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    xs="12"
                    md="auto"
                    className="d-flex mb-5 mb-md-2"
                  >
                    <Button
                      onClick={handleSubmit}
                      size="lg"
                      className="my-auto w-100 w-md-auto"
                    >
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="me-2"
                      />
                      <span>Search</span>
                    </Button>
                  </Form.Group>

                  {isAdmin && (
                    <Form.Group as={Col} md="auto" className="mb-3">
                      <Form.Label className="label">
                        Showing Receipts:
                      </Form.Label>
                      <Form.Switch
                        id="voided"
                        type="switch"
                        className="d-flex ps-0 pt-2"
                      >
                        <Form.Switch.Label className="fs-5 text-success">
                          Active
                        </Form.Switch.Label>
                        <Form.Switch.Input
                          id="voided"
                          value={voided}
                          checked={voided}
                          onChange={() => {
                            setVoided(!voided);
                            handleSubmit();
                          }}
                          className="mx-2 my-auto"
                        />
                        <Form.Switch.Label className="fs-5 text-primary">
                          Voided
                        </Form.Switch.Label>
                      </Form.Switch>
                    </Form.Group>
                  )}
                </Row>
              </FormikForm>

              <AlertErrors show={showErrors} message={errorMessages} />

              {receipts.total > 0 && (
                <>
                  <div className="label w-100 text-end pb-3">
                    {receipts.total} Result{receipts.total > 1 ? 's' : ''}:
                  </div>

                  {receipts?.data?.map((receipt, index) => (
                    <Table
                      key={receipt.id}
                      borderless
                      size="sm"
                      className="d-lg-none d-print-none result-list__row"
                    >
                      <tbody>
                        <tr>
                          <th style={{ width: '110px' }}>Receipt #</th>
                          <td>
                            <Link
                              to={`/receipt/${receipt.id}`}
                              className="fw-bold"
                            >
                              {String(receipt.id).padStart(5, '0')}
                            </Link>

                            {receipt.voidedByUserId && (
                              <>
                                <div className="void-stamp void-stamp--print float-end">
                                  VOID
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>Seller</th>
                          <td className="word-break-all">
                            {receipt.seller.name}
                            <br />
                            <span className="small">
                              {receipt.seller.email}
                            </span>
                          </td>
                        </tr>
                        {isAdmin && (
                          <tr>
                            <th>Created By</th>
                            <td>{receipt.user.username}</td>
                          </tr>
                        )}
                        <tr>
                          <th>Total</th>
                          <td>{currency(receipt.total).format()}</td>
                        </tr>
                        <tr>
                          <th>Date</th>
                          <td>
                            {moment(receipt.created_at).format('L')}
                            <span className="d-lg-none">&emsp;</span>
                            <br className="d-none d-lg-block" />
                            {moment(receipt.created_at).format('LT')}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  ))}

                  <Row className="d-none d-lg-flex result-list__header-row d-print-none">
                    <Col lg="auto" className="result-list__receipt">
                      Receipt #
                    </Col>
                    <Col lg>Seller</Col>
                    {isAdmin && <Col lg>User</Col>}
                    {voided && (
                      <Col xs="12" lg="auto" className="result-list__void" />
                    )}
                    <Col lg="auto" className="text-lg-end result-list__total">
                      Total
                    </Col>
                    <Col lg="auto" className="text-lg-end result-list__date">
                      Date
                    </Col>
                  </Row>
                  <div className="result-list d-print-none d-none d-lg-block">
                    {receipts?.data?.map((receipt) => (
                      <Row
                        key={receipt.id}
                        className="result-list__row word-break-all position-relative"
                      >
                        <Col
                          xs="12"
                          lg="auto"
                          className={`result-list__receipt text-wrap ${
                            voided ? 'text-danger' : ''
                          }`}
                        >
                          <Link
                            to={`/receipt/${receipt.id}`}
                            className="fw-bold"
                          >
                            {String(receipt.id).padStart(5, '0')}
                          </Link>
                        </Col>
                        <Col xs="12" lg className="text-wrap">
                          {receipt.seller.name}
                          <br />
                          <span className="small">{receipt.seller.email}</span>
                        </Col>
                        {isAdmin && (
                          <Col xs="12" lg>
                            {receipt.user.username}
                          </Col>
                        )}
                        {receipt.voidedByUserId && (
                          <Col xs="12" lg="auto" className="result-list__void">
                            <div className="void-stamp void-stamp--row">
                              VOID
                            </div>
                          </Col>
                        )}
                        <Col
                          xs="12"
                          lg="auto"
                          className="text-lg-end result-list__total"
                        >
                          {currency(receipt.total).format()}
                        </Col>
                        <Col
                          xs="12"
                          lg="auto"
                          className="text-wrap text-lg-end result-list__date"
                        >
                          {moment(receipt.created_at).format('L')}
                          <span className="d-lg-none">&emsp;</span>
                          <br className="d-none d-lg-block" />
                          {moment(receipt.created_at).format('LT')}
                        </Col>
                      </Row>
                    ))}
                  </div>

                  <Table striped className="d-none d-print-table">
                    <thead>
                      <tr>
                        <th>Receipt #</th>
                        <th>Seller</th>
                        {isAdmin && <th>User</th>}
                        {voided && <th></th>}
                        <th className="text-end">Total</th>
                        <th className="text-end">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {receipts?.data?.map((receipt) => (
                        <tr key={receipt.id} className="position-relative">
                          <td>{String(receipt.id).padStart(5, '0')}</td>
                          <td>
                            {receipt.seller.name}
                            <br />
                            <span className="small">
                              {receipt.seller.email}
                            </span>
                          </td>
                          {isAdmin && <td>{receipt.user.username}</td>}
                          {receipt.voidedByUserId && (
                            <td className="text-center">
                              <div className="void-stamp void-stamp--print">
                                VOID
                              </div>
                            </td>
                          )}
                          <td className="text-end">
                            {currency(receipt.total).format()}
                          </td>
                          <td className="text-end">
                            {moment(receipt.created_at).format('L')}
                            <span className="d-lg-none">&emsp;</span>
                            <br className="d-none d-lg-block" />
                            {moment(receipt.created_at).format('LT')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}

              {receipts.total > 0 && (
                <ListPagination
                  baseUrl={`receipts`}
                  searchTerm={values.receiptSearch}
                  pagination={receipts}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
            </>
          )}
        </Formik>

        {receipts.total < 1 && formSubmitted && (
          <p className="lead text-primary">
            <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
            No results
          </p>
        )}
      </Container>
      <Spinner show={showSpinner} />
    </>
  );
}

export default Receipts;
