import { useState } from 'react';
import { Form as FormikForm, Formik } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import API from '../../Helpers/API';
import ProfessionsService from '../../Helpers/ProfessionsService';
import { professionValidation } from '../../Helpers/ValidationSchemas/validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { catchApiResponseError } from '../../Helpers/catchApiResponseError';
import TextInput from '../TextInput';
import ModalConfirm from '../ModalConfirm';
import Spinner from '../Spinner';

const EditProfession = ({
  profession,
  setEditIndex,
  professionId,
  getProfessions,
  success,
  errors,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const { setSuccessMessage, setShowSuccess } = success;
  const { setErrorMessages, setShowErrors } = errors;
  const [showSpinner, setShowSpinner] = useState(false);

  const handleSubmit = async (input) => {
    try {
      setShowSpinner(true);
      setShowErrors(false);
      setShowSuccess(false);
      const response = await new ProfessionsService(new API()).updateProfession(
        {
          id: professionId,
          profession: input.profession,
        },
      );
      if (response.status > 199 && response.status < 300) {
        getProfessions();
        setSuccessMessage('Profession updated');
        setShowSuccess(true);
        setEditIndex();
      }
      response && setShowSpinner(false);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
      setShowSpinner(false);
    }
  };

  const deleteProfession = async (id) => {
    try {
      setShowSpinner(true);
      const response = await new ProfessionsService(new API()).deleteProfession(
        id,
      );
      if (response.status > 199 && response.status < 300) {
        getProfessions();
        setSuccessMessage(`${profession} successfully deleted`);
        setShowSuccess(true);
        setEditIndex();
      }
      response && setShowSpinner(false);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
      setShowSpinner(false);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={{
          profession: profession ?? '',
        }}
        validationSchema={professionValidation}
        validateOnMount
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          isValid,
          values,
          errors,
          setFieldValue,
          initialValues,
        }) => (
          <FormikForm onSubmit={handleSubmit} className="">
            <Row className="">
              <Col md={6} className="d-flex align-items-center">
                <TextInput
                  fieldName="profession"
                  label=""
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  reset
                  resetDisabled={profession === values.profession}
                  resetClick={() =>
                    setFieldValue('profession', initialValues.profession)
                  }
                  hideLabel
                />
              </Col>
              <Col
                md={6}
                className="d-flex justify-content-end align-items-center mb-3"
              >
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => setEditIndex()}
                >
                  <FontAwesomeIcon
                    title="delete"
                    icon={solid('xmark')}
                    className="me-2"
                  />
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant="outline-primary"
                  className="mx-3"
                  onClick={() => setShowConfirm(true)}
                >
                  <FontAwesomeIcon
                    title="delete"
                    icon={solid('trash-can')}
                    className="me-2"
                  />
                  Delete
                </Button>
                <Button
                  variant="primary"
                  onClick={() => handleSubmit()}
                  disabled={profession === values.profession}
                >
                  <FontAwesomeIcon
                    title="save"
                    icon={solid('bookmark')}
                    className="me-2"
                  />
                  Save
                </Button>
              </Col>
            </Row>
            <ModalConfirm
              show={showConfirm}
              title={'Confirm Delete'}
              text={`Are you sure you want to delete ${profession}?`}
              handleClose={() => setShowConfirm(false)}
              confirmButtonText={'Confirm'}
              confirmClick={() => deleteProfession(professionId)}
              doubleConfirm
            />
          </FormikForm>
        )}
      </Formik>
      <Spinner show={showSpinner} />
    </>
  );
};

export default EditProfession;
