import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FieldArray, Formik, Field, Form as FormikForm } from 'formik';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Badge,
  Accordion,
  Stack,
} from 'react-bootstrap';
import API from '../../Helpers/API';
import ReceiptService from '../../Helpers/ReceiptService';
import { receiptValidation } from '../../Helpers/ValidationSchemas/validation';
import AsyncSelect from 'react-select/async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import useAuth from '../../Hooks/useAuth';
import SellerService from '../../Helpers/SellerService';
import { plaintextPhone } from '../../Helpers/phoneHelpers';
import { MAX_FILE_SIZE } from '../../constants';
import ModalConfirm from '../ModalConfirm';
import SelectInput from './SelectInput';
import WeightInput from './WeightInput';
import TareInput from './TareInput';
import NetWeight from './NetWeight';
import ImageInput from './ImageInput';
import ConfirmReceipt from './ConfirmReceipt';
import AccordionToggleButton from './AccordionToggleButton';
import AlertErrors from '../AlertErrors';
import BackLinkTitle from '../BackLinkTitle';
import Spinner from '../Spinner';
import currency from 'currency.js';
import ModalConfirmItem from './ModalConfirmItem';
import ConfirmedItem from './ConfirmedItem';
import Total from './Total';
import ReactRouterPrompt from 'react-router-prompt';
import { catchApiResponseError } from '../../Helpers/catchApiResponseError';
import imageCompression from 'browser-image-compression';

function AddReceipt() {
  const navigate = useNavigate();
  const IMAGE_METAL = 'IMAGE_METAL';
  const IMAGE_WEIGHT = 'IMAGE_WEIGHT';

  const formData = new FormData();
  const { auth } = useAuth();
  const sellerRef = useRef();
  const sellerSearchDefault = { value: '', label: '' };
  const cartItemDefault = {
    metalsCategory: '',
    metalsCategoryId: null,
    metalType: '',
    metalTypeId: null,
    weight: '',
    tare: 0,
    imageMetal: {
      file: {},
      preview: '',
    },
    imageWeight: {
      file: {},
      preview: '',
    },
  };
  const receiptDefault = {
    seller: sellerSearchDefault,
    cartItems: [cartItemDefault],
  };
  const [searchInput, setSearchInput] = useState('');
  const [metals, setMetals] = useState([]);
  const [receipt, setReceipt] = useState(receiptDefault);
  const [confirmedItems, setConfirmedItems] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [showConfirmSubmit, setShowConfirmSubmit] = useState(false);
  const [activeKey, setActiveKey] = useState(['0']);
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showResetConfirm, setShowResetConfirm] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showAddConfirm, setShowAddConfirm] = useState(false);
  const [defaultSellers, setDefaultSellers] = useState([
    { value: '', label: '' },
  ]);
  const [blockNav, setBlockNav] = useState(true);

  const { sellerId } = useParams();

  const loadMetals = useCallback(async () => {
    try {
      const response = await new ReceiptService(new API()).getMetals();
      setMetals(response.data);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
    }
  }, []);

  const metalsArr = [].concat(...Object.keys(metals).map((v) => metals[v]));

  const getMetalPrice = (id = null, weight = null, format = true) => {
    if (id) {
      const metalPrice = metalsArr.find(
        (metal) => parseInt(metal.id) === parseInt(id),
      ).price;

      if (format) {
        return weight
          ? currency(metalPrice * weight).format()
          : `${currency(metalPrice).format()}/lb`;
      } else {
        return weight ? metalPrice * weight : metalPrice;
      }
    }
    return null;
  };

  const loadSellers = async (inputValue, callback) => {
    const searchInput = inputValue;

    if (!searchInput) {
      return callback([]);
    }

    try {
      const response = await new SellerService(new API()).getSellers(
        searchInput,
      );
      callback(parseSellersList(response.data.data));
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
    }
  };

  const parseSellersList = (data) => {
    const options = data.map((seller) => {
      return {
        value: seller.id,
        label: `${seller.name} - ${plaintextPhone(seller.phoneNumber)}`,
        ...seller,
      };
    });

    return options;
  };

  const setMetalsCategory = (values, e, index) => {
    const metalsCategoryId =
      e.target.options[e.target.selectedIndex].getAttribute('data-id');
    const updated = { ...values };
    updated.cartItems[index].metalsCategoryId = parseInt(metalsCategoryId);
    updated.cartItems[index].metalsCategory = e.target.value;

    setReceipt(updated);
  };

  const setMetalType = (values, e, index) => {
    const metalTypeId =
      e.target.options[e.target.selectedIndex].getAttribute('data-id');
    const updated = { ...values };
    updated.cartItems[index].metalTypeId = parseInt(metalTypeId);
    updated.cartItems[index].metalType = e.target.value;

    setReceipt(updated);
  };

  const handleAddItem = async (itemIndex) => {
    setErrorMessages([]);
    setShowErrors(false);
    // TO DO: compress images before uploading
    const item = receipt.cartItems[itemIndex];

    formData.set('userId', auth.user.id);
    formData.set('sellerId', receipt.seller.id);
    formData.set('itemId', itemIndex + 1);
    formData.set('metalsCategoryId', item.metalsCategoryId);
    formData.set('metalTypeId', item.metalTypeId);
    formData.set('weight', item.weight);
    formData.set('tare', item.tare);
    formData.set('imageMetal', item.imageMetal.file);
    formData.set('imageWeight', item.imageWeight.file);

    try {
      setShowSpinner(true);
      const response = await new ReceiptService(
        new API(true, 'multipart/form-data'),
      ).addReceiptItem(formData);
      if (response.status === 201) {
        let itemObj = {};
        for (var pair of formData.entries()) {
          itemObj[pair[0]] = pair[1];
        }
        setConfirmedItems([...confirmedItems, itemObj]);
        setShowSpinner(false);
      }
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
      setShowSpinner(false);
    }
  };

  const handleRemoveItem = async (itemIndex, isUnconfirmed) => {
    if (!isUnconfirmed) {
      try {
        setShowSpinner(true);
        const data = {
          userId: auth.user.id,
          sellerId: receipt.seller.id,
          itemId: itemIndex + 1,
        };
        const response = await new ReceiptService(
          new API(true),
        ).removeReceiptItem(data);
        if (response) {
          setConfirmedItems(
            confirmedItems.filter((_, index) => index !== itemIndex),
          );
          setShowSpinner(false);
        }
      } catch (err) {
        catchApiResponseError(err, setErrorMessages, setShowErrors);
        setShowSpinner(false);
      }
    }

    receipt.cartItems.splice(itemIndex, 1);

    setReceipt({
      ...receipt,
      cartItems: receipt.cartItems,
    });
  };

  const handleImageSelect = async (values, event, index, image, handleBlur) => {
    const file = event?.target?.files?.[0];

    if (file) {
      try {
        setShowSpinner(true);
        const updated = { ...values };
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 3,
          maxWidthOrHeight: 3840,
        });

        const objectUrl = URL.createObjectURL(compressedFile);

        if (image === IMAGE_METAL) {
          updated.cartItems[index].imageMetal.file = compressedFile;
          updated.cartItems[index].imageMetal.preview =
            file.size > MAX_FILE_SIZE ? '' : objectUrl;
        } else if (image === IMAGE_WEIGHT) {
          updated.cartItems[index].imageWeight.file = compressedFile;
          updated.cartItems[index].imageWeight.preview =
            file.size > MAX_FILE_SIZE ? '' : objectUrl;
        }

        await setReceipt(updated);
        handleBlur(event);
        setShowSpinner(false);
      } catch (err) {
        catchApiResponseError(err, setErrorMessages, setShowErrors);
        setShowSpinner(false);
      }
    }
  };

  const handleReset = async () => {
    const data = {
      userId: auth.user.id,
      sellerId: receipt.seller.id,
    };

    try {
      const response = await new ReceiptService(new API(true)).clearItems(data);
      if (response.status === 204) {
        setConfirmedItems([]);
        setShowErrors(false);
        setErrorMessages([]);
      }
    } catch (err) {
      setShowConfirmSubmit(false);
      catchApiResponseError(err, setErrorMessages, setShowErrors);
    }

    setReceipt(receiptDefault);
  };

  const handleSubmit = async () => {
    setShowErrors(false);
    const userId = auth.user.id;
    const sellerId = receipt.seller.id;

    try {
      setBlockNav(false);
      setShowSpinner(true);
      const response = await new ReceiptService(new API(true)).createReceipt({
        sellerId,
        userId,
      });
      const data = response.data;
      sessionStorage.setItem('receiptAdded', data.id);
      data && setShowSpinner(false);
      navigate(`/receipt/${data.id}`);
    } catch (err) {
      setBlockNav(true);
      setShowConfirmSubmit(false);
      catchApiResponseError(err, setErrorMessages, setShowErrors);
      setShowSpinner(false);
    }
  };

  const getSeller = useCallback(async () => {
    try {
      const response = await new SellerService(new API()).getSeller(sellerId);
      setReceipt((receipt) => ({
        cartItems: receipt.cartItems,
        seller: parseSellersList(response.data)[0],
      }));
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
    }
  }, [sellerId]);

  const getSellers = useCallback(async () => {
    try {
      const response = await new SellerService(new API()).getSellers();
      setDefaultSellers(parseSellersList(response.data.data));
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
    }
  }, []);

  const getTotal = (invoiceComplete, currentItemTotal) => {
    let receiptTotal = 0;

    confirmedItems?.map((item) => {
      const metalPrice = metalsArr.find(
        (metal) => parseInt(metal.id) === parseInt(item.metalTypeId),
      ).price;
      return (receiptTotal += metalPrice * (item.weight - item.tare));
    });

    return currency(
      (receiptTotal += invoiceComplete ? 0 : currentItemTotal),
    ).format();
  };

  useEffect(() => {
    sellerRef.current.focus();
    loadMetals();

    sellerId ? getSeller() : getSellers();
  }, [getSeller, getSellers, loadMetals, sellerId]);

  return (
    <>
      <ReactRouterPrompt when={receipt?.seller?.value !== '' && blockNav}>
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            <ModalConfirm
              show
              title="Wait!"
              text="You are about to lose all your work. Do you want to continue?"
              handleClose={onCancel}
              confirmButtonText="Confirm"
              confirmClick={() => {
                if (confirmedItems.length > 0) handleReset();
                setBlockNav(false);
                onConfirm();
              }}
            />
          )
        }
      </ReactRouterPrompt>

      <Container>
        <BackLinkTitle
          url="/receipts"
          srText="Back to receipts"
          title="Add New Receipt"
        />
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={receipt}
          validationSchema={receiptValidation}
          validateOnMount
          validateOnChange
          validateOnBlur
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            resetForm,
            isValid,
            setFieldValue,
            initialValues,
            handleBlur,
          }) => (
            <>
              <FormikForm onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Field name="seller" type="select">
                    {({ field, form }) => {
                      return (
                        <>
                          <Form.Group as={Col} md={6} controlId="seller">
                            <Form.Label className="label">
                              Choose Seller{' '}
                              <span className="small">
                                (start typing to search)
                              </span>
                            </Form.Label>
                            <InputGroup>
                              <AsyncSelect
                                name="seller"
                                ref={sellerRef}
                                className="mb-2 w-100"
                                defaultValue={sellerSearchDefault}
                                value={values.seller}
                                inputValue={searchInput}
                                loadOptions={loadSellers}
                                defaultOptions={defaultSellers}
                                isDisabled={confirmedItems.length > 0}
                                onChange={(value) => {
                                  setSearchInput();

                                  setReceipt({
                                    ...values,
                                    seller: value,
                                  });
                                }}
                                onInputChange={(inputValue, action) => {
                                  let value = inputValue;
                                  if (action.action === 'input-change') {
                                    setSearchInput(value);
                                  }
                                }}
                                placeholder="Type to search"
                                components={{
                                  IndicatorSeparator: () => null,
                                  DropdownIndicator: () => null,
                                  NoOptionsMessage: () => (
                                    <div className="text-center my-2">
                                      No sellers found.
                                    </div>
                                  ),
                                }}
                              />
                            </InputGroup>
                          </Form.Group>
                        </>
                      );
                    }}
                  </Field>
                </Row>

                <AlertErrors show={showErrors} message={errorMessages} />

                <div
                  className={`mb-3 ${
                    !receipt.seller.value ? 'form-disabled' : ''
                  }`}
                >
                  <FieldArray name="cartItems">
                    {({ remove, push }) => {
                      const invoiceComplete =
                        confirmedItems?.length === values?.cartItems?.length;
                      const metalId =
                        values?.cartItems[values.cartItems.length - 1]
                          ?.metalTypeId || null;
                      const metalWeight =
                        values.cartItems[values.cartItems.length - 1]?.weight ||
                        null;
                      const tareWeight =
                        values.cartItems[values.cartItems.length - 1]?.tare ||
                        null;
                      const currentItemTotal =
                        metalWeight &&
                        getMetalPrice(metalId, metalWeight - tareWeight, false);

                      return (
                        <>
                          <Total
                            invoiceComplete={invoiceComplete}
                            receiptTotal={getTotal(
                              invoiceComplete,
                              currentItemTotal,
                              false,
                            )}
                          />

                          <Accordion
                            defaultActiveKey={['0']}
                            alwaysOpen
                            className="mb-4"
                            activeKey={activeKey}
                          >
                            {values?.cartItems?.length > 0 &&
                              values?.cartItems?.map((item, itemIndex) => {
                                const currentMetalId =
                                  values?.cartItems[itemIndex]?.metalTypeId ||
                                  null;
                                const currentNetWeight =
                                  values?.cartItems[itemIndex]?.weight -
                                    values?.cartItems[itemIndex]?.tare || null;
                                const itemConfirmed =
                                  confirmedItems.length >= itemIndex + 1;

                                return (
                                  <React.Fragment key={itemIndex}>
                                    <Card>
                                      <Card.Header>
                                        <Row>
                                          <Col className="d-flex align-items-center">
                                            <AccordionToggleButton
                                              eventKey={itemIndex.toString()}
                                              disabled={!receipt.seller.value}
                                              callback={() => {
                                                if (
                                                  activeKey.includes(
                                                    itemIndex.toString(),
                                                  )
                                                ) {
                                                  setActiveKey(
                                                    activeKey.filter(
                                                      (v) =>
                                                        v !==
                                                        itemIndex.toString(),
                                                    ),
                                                  );
                                                } else {
                                                  setActiveKey([
                                                    ...activeKey,
                                                    itemIndex.toString(),
                                                  ]);
                                                }
                                              }}
                                            >
                                              <Card.Title className="mb-0">
                                                Item {itemIndex + 1}
                                              </Card.Title>
                                            </AccordionToggleButton>
                                          </Col>
                                          <Col xs="auto">
                                            {confirmedItems?.length <
                                            itemIndex + 1 ? (
                                              <Button
                                                type="button"
                                                className="mx-2"
                                                variant={
                                                  isValid
                                                    ? 'primary'
                                                    : 'outline-secondary'
                                                }
                                                onClick={() =>
                                                  setShowAddConfirm(true)
                                                }
                                                disabled={!isValid}
                                              >
                                                {isValid && 'Add this item '}
                                                <FontAwesomeIcon
                                                  icon={solid('arrow-right')}
                                                />
                                              </Button>
                                            ) : (
                                              <>
                                                <Badge
                                                  bg="success"
                                                  className="me-2"
                                                >
                                                  <FontAwesomeIcon
                                                    className="me-1"
                                                    icon={solid('check')}
                                                  />
                                                  Added
                                                </Badge>
                                              </>
                                            )}
                                            <Button
                                              variant="outline-secondary"
                                              onClick={() => {
                                                setDeleteId(itemIndex);
                                                setShowDeleteConfirm(true);
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                icon={solid('trash-can')}
                                              />
                                            </Button>
                                          </Col>
                                        </Row>
                                      </Card.Header>
                                      <Accordion.Collapse
                                        eventKey={itemIndex.toString()}
                                      >
                                        <>
                                          {itemConfirmed ? (
                                            <Card.Body>
                                              <ConfirmedItem
                                                values={item}
                                                getMetalPrice={getMetalPrice}
                                              />
                                            </Card.Body>
                                          ) : (
                                            <Card.Body>
                                              <Row>
                                                <SelectInput
                                                  name="metalsCategory"
                                                  label="Metal Category"
                                                  fieldName={`cartItems.${itemIndex}.metalsCategory`}
                                                  values={values}
                                                  itemIndex={itemIndex}
                                                  handleBlur={handleBlur}
                                                  receipt={receipt}
                                                  disabled={
                                                    !receipt.seller.value
                                                  }
                                                  onChange={(e) =>
                                                    setMetalsCategory(
                                                      values,
                                                      e,
                                                      itemIndex,
                                                    )
                                                  }
                                                  options={Object.keys(
                                                    metals,
                                                  ).map((category, index) => (
                                                    <option
                                                      value={category}
                                                      data-id={
                                                        metals[category][0][
                                                          'metalsCategoryId'
                                                        ]
                                                      }
                                                      key={index}
                                                    >
                                                      {category}
                                                    </option>
                                                  ))}
                                                />

                                                <SelectInput
                                                  name="metalType"
                                                  label="Metal Type"
                                                  fieldName={`cartItems.${itemIndex}.metalType`}
                                                  values={values}
                                                  itemIndex={itemIndex}
                                                  handleBlur={handleBlur}
                                                  receipt={receipt}
                                                  disabled={
                                                    !receipt.seller.value ||
                                                    !values.cartItems[itemIndex]
                                                      .metalsCategory
                                                  }
                                                  onChange={(e) =>
                                                    setMetalType(
                                                      values,
                                                      e,
                                                      itemIndex,
                                                    )
                                                  }
                                                  options={(
                                                    metals[
                                                      values.cartItems[
                                                        itemIndex
                                                      ].metalsCategory
                                                    ] || []
                                                  ).map((metal, index) => (
                                                    <option
                                                      value={metal.type}
                                                      data-price={metal.price}
                                                      data-id={metal.id}
                                                      key={index}
                                                    >
                                                      {metal.type}
                                                    </option>
                                                  ))}
                                                  labelRight={getMetalPrice(
                                                    currentMetalId,
                                                  )}
                                                />
                                              </Row>
                                              <Row>
                                                <WeightInput
                                                  name="weight"
                                                  label="Weight (lbs)"
                                                  fieldName={`cartItems.${itemIndex}.weight`}
                                                  values={values}
                                                  itemIndex={itemIndex}
                                                  handleBlur={handleBlur}
                                                  receipt={receipt}
                                                  setFieldValue={setFieldValue}
                                                />

                                                <TareInput
                                                  name="tare"
                                                  label="Tare (lbs)"
                                                  fieldName={`cartItems.${itemIndex}.tare`}
                                                  values={values}
                                                  itemIndex={itemIndex}
                                                  handleBlur={handleBlur}
                                                  receipt={receipt}
                                                  setFieldValue={setFieldValue}
                                                />

                                                <NetWeight
                                                  name="netWeight"
                                                  fieldName={`cartItems.${itemIndex}.netWeight`}
                                                  weight={
                                                    values.cartItems[itemIndex]
                                                      .weight
                                                  }
                                                  tare={
                                                    values.cartItems[itemIndex]
                                                      .tare
                                                  }
                                                  labelRight={
                                                    currentNetWeight &&
                                                    getMetalPrice(
                                                      currentMetalId,
                                                      currentNetWeight,
                                                    )
                                                  }
                                                />
                                              </Row>
                                              <Row>
                                                <ImageInput
                                                  name="imageMetal"
                                                  label="Add Metal Photo"
                                                  values={values}
                                                  itemIndex={itemIndex}
                                                  handleBlur={handleBlur}
                                                  receipt={receipt}
                                                  setFieldValue={setFieldValue}
                                                  handleImageSelect={
                                                    handleImageSelect
                                                  }
                                                  imageType={IMAGE_METAL}
                                                />

                                                <ImageInput
                                                  name="imageWeight"
                                                  label="Add Weight Photo"
                                                  values={values}
                                                  itemIndex={itemIndex}
                                                  handleBlur={handleBlur}
                                                  receipt={receipt}
                                                  setFieldValue={setFieldValue}
                                                  handleImageSelect={
                                                    handleImageSelect
                                                  }
                                                  imageType={IMAGE_WEIGHT}
                                                />
                                              </Row>
                                            </Card.Body>
                                          )}
                                          {confirmedItems?.length <
                                            itemIndex + 1 && (
                                            <Card.Footer className="text-end">
                                              <Button
                                                type="button"
                                                variant={
                                                  isValid
                                                    ? 'primary'
                                                    : 'outline-secondary'
                                                }
                                                onClick={() =>
                                                  setShowAddConfirm(true)
                                                }
                                                disabled={!isValid}
                                              >
                                                {isValid && 'Add this item '}
                                                <FontAwesomeIcon
                                                  icon={solid('arrow-right')}
                                                />
                                              </Button>
                                            </Card.Footer>
                                          )}
                                        </>
                                      </Accordion.Collapse>
                                    </Card>
                                  </React.Fragment>
                                );
                              })}
                          </Accordion>

                          <Total
                            invoiceComplete={invoiceComplete}
                            receiptTotal={getTotal(
                              invoiceComplete,
                              currentItemTotal,
                              false,
                            )}
                          />

                          <Stack
                            className="col-md-6"
                            direction="horizontal"
                            gap={2}
                          >
                            <Button
                              className="w-100"
                              size="lg"
                              variant={`${
                                !receipt?.seller?.value ||
                                !isValid ||
                                confirmedItems?.length !==
                                  values?.cartItems?.length
                                  ? 'outline-secondary'
                                  : 'primary'
                              } `}
                              onClick={() => {
                                setActiveKey([
                                  confirmedItems?.length.toString(),
                                ]);
                                push(cartItemDefault);
                              }}
                              disabled={
                                !receipt?.seller?.value ||
                                confirmedItems?.length !==
                                  values?.cartItems?.length
                              }
                            >
                              <FontAwesomeIcon icon={solid('plus')} />
                              <span className="mx-1">Add Item</span>
                            </Button>
                            <Button
                              className="w-50"
                              variant="outline-secondary"
                              onClick={() => setShowResetConfirm(true)}
                              size="lg"
                              disabled={confirmedItems?.length < 1}
                            >
                              <FontAwesomeIcon icon={solid('ban')} />
                              <span className="mx-1">Reset</span>
                            </Button>
                          </Stack>

                          <ModalConfirmItem
                            show={showAddConfirm}
                            getMetalPrice={getMetalPrice}
                            handleClose={() => setShowAddConfirm(false)}
                            confirmClick={() =>
                              handleAddItem(values.cartItems.length - 1)
                            }
                            values={
                              values?.cartItems[values.cartItems.length - 1]
                            }
                          />

                          <ModalConfirm
                            show={showDeleteConfirm}
                            title="Confirm Delete"
                            text={`Are you sure you want to delete item ${
                              deleteId + 1
                            }?`}
                            handleClose={() => {
                              setShowDeleteConfirm(false);
                              setDeleteId();
                            }}
                            confirmButtonText="Confirm"
                            confirmClick={() => {
                              handleRemoveItem(
                                deleteId,
                                confirmedItems?.length !==
                                  values?.cartItems?.length &&
                                  deleteId === values?.cartItems?.length - 1,
                              );
                              remove(deleteId);
                            }}
                          />

                          <ModalConfirm
                            show={showResetConfirm}
                            title="Confirm Receipt Reset"
                            text="Are you sure you want to reset this receipt?"
                            handleClose={() => setShowResetConfirm(false)}
                            confirmButtonText="Confirm"
                            confirmClick={() => {
                              handleReset();
                              resetForm();
                            }}
                          />
                        </>
                      );
                    }}
                  </FieldArray>
                  <ConfirmReceipt
                    show={showConfirmSubmit}
                    onClose={() => setShowConfirmSubmit(false)}
                    confirmedItems={confirmedItems}
                    metals={metals}
                    receiptTotal={getTotal(metals)}
                    onSubmit={handleSubmit}
                  />
                </div>
                <Stack className="col-md-6" direction="horizontal">
                  <Button
                    variant="primary"
                    onClick={() => setShowConfirmSubmit(true)}
                    className="w-100"
                    size="lg"
                    disabled={
                      !receipt?.seller?.value ||
                      !isValid ||
                      values?.cartItems?.length !== confirmedItems?.length ||
                      confirmedItems.length < 1
                    }
                  >
                    Confirm Receipt
                  </Button>
                </Stack>
              </FormikForm>
            </>
          )}
        </Formik>
      </Container>
      <Spinner show={showSpinner} />
    </>
  );
}

export default AddReceipt;
