import { useEffect, useState } from 'react';
import { Form as FormikForm, Formik } from 'formik';
import { Button, Col, Container, Row } from 'react-bootstrap';
import API from '../../Helpers/API';
import AuthService from '../../Helpers/AuthService';
import { useNavigate } from 'react-router-dom';
import { updatePasswordValidation } from '../../Helpers/ValidationSchemas/validation';
import AlertErrors from '../AlertErrors';
import TextInput from '../TextInput';
import { useSearchParams } from 'react-router-dom';
import { catchApiResponseError } from '../../Helpers/catchApiResponseError';
import BackLinkTitle from '../BackLinkTitle';
import Spinner from '../Spinner';

function UpdatePassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [paramVals, setParamVals] = useState({
    email: '',
    token: '',
  });
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  const handleSubmit = async (input, { resetForm, validateForm }) => {
    try {
      setShowSpinner(true);
      setShowErrors(false);
      const response = await new AuthService(new API(false)).updatePassword({
        email: paramVals.email,
        token: paramVals.token,
        oldPassword: input.oldPassword,
        password: input.newPassword,
        password_confirmation: input.confirmPassword,
      });
      if (response) {
        sessionStorage.setItem('passwordUpdate', true);
        navigate('/login');
        setShowSpinner(false);
      }
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors, {
        401: 'Unauthorized',
        500: 'Your token may have expired. Please contact an admin',
      });
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    const email = searchParams.get('email');
    const token = searchParams.get('token');

    if (email && token) {
      setParamVals({
        email: email,
        token: token,
      });
    } else {
      navigate('/login');
    }
  }, [navigate, searchParams]);

  return (
    <>
      <Container>
        <div className="d-flex justify-content-center">
          <div className="mxw-400 w-100">
            <BackLinkTitle
              url="/login"
              srText="Back to login"
              title="Update Password"
            />

            <AlertErrors show={showErrors} message={errorMessages} />

            <Formik
              enableReinitialize
              onSubmit={handleSubmit}
              initialValues={{
                email: paramVals.email,
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
              }}
              validationSchema={updatePasswordValidation}
              validateOnMount
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                validateForm,
                resetForm,
                touched,
                isValid,
                values,
                errors,
              }) => (
                <FormikForm onSubmit={handleSubmit}>
                  <TextInput
                    fieldName="email"
                    label="Email"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    type="email"
                    size="md"
                    readOnly
                    disabled
                  />

                  <TextInput
                    fieldName="oldPassword"
                    label="Old Password"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    type="password"
                  />

                  <TextInput
                    fieldName="newPassword"
                    label="New Password"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    type="password"
                  />

                  <TextInput
                    fieldName="confirmPassword"
                    label="Confirm New Password"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    type="password"
                  />

                  <Row>
                    <Col xs="auto">
                      <Button
                        variant="primary"
                        disabled={!isValid || !values}
                        onClick={handleSubmit}
                      >
                        Update Password
                      </Button>
                    </Col>
                  </Row>
                </FormikForm>
              )}
            </Formik>
          </div>
        </div>
      </Container>
      <Spinner show={showSpinner} />
    </>
  );
}

export default UpdatePassword;
