import { AUTH_COOKIE_NAME } from '../constants';
import Cookies from 'js-cookie';

const isLoggedIn = () => {
  let cookie = {};

  if (Cookies.get(AUTH_COOKIE_NAME)) {
    cookie = JSON.parse(Cookies.get(AUTH_COOKIE_NAME));
  }

  return cookie?.user?.authLevel ?? false;
};

export default isLoggedIn;
