import { Col, Button, Form, Image, InputGroup } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

function ImageInput({
  name,
  label,
  values,
  itemIndex,
  handleBlur,
  receipt,
  setFieldValue,
  handleImageSelect,
  imageType,
}) {
  const handleDelete = async (
    setFieldValue,
    itemIndex,
    name,
    handleImageSelect,
  ) => {
    await setFieldValue(`cartItems.${itemIndex}.${name}`, null);

    setFieldValue(
      `cartItems.${itemIndex}.${name}`,
      {
        file: {},
        preview: '',
      },
      true,
    );

    handleImageSelect(
      values,
      {
        file: {},
        preview: '',
      },
      itemIndex,
      imageType,
    );
  };

  return (
    <Field name={`cartItems.${itemIndex}.${name}`}>
      {({ field, form }) => {
        const item = values.cartItems[itemIndex];
        const isValid = !form.errors?.cartItems?.[itemIndex]?.[name];
        const isTouched = form.touched?.cartItems?.[itemIndex]?.[name];
        const isInvalid = isTouched && !isValid;

        return (
          <>
            {item[name] && (
              <Form.Group
                as={Col}
                xs="12"
                sm="6"
                controlId={`cartItems.${itemIndex}.${name}`}
                className="mt-2"
              >
                <Form.Label className="label">{label}</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="file"
                    capture="environment"
                    accept=".png, .jpg, .jpeg"
                    isValid={isTouched && isValid}
                    isInvalid={isInvalid}
                    onChange={(e) => {
                      handleImageSelect(
                        values,
                        e,
                        itemIndex,
                        imageType,
                        handleBlur,
                      );
                    }}
                    disabled={!receipt.seller.value}
                    onBlur={handleBlur}
                    size="lg"
                  />
                  <ErrorMessage
                    component="div"
                    name={field.name}
                    className="invalid-feedback"
                  />
                  <Button
                    variant="outline-primary"
                    disabled={!receipt.seller.value || !item[name]?.file?.size}
                    onClick={() =>
                      handleDelete(
                        setFieldValue,
                        itemIndex,
                        name,
                        handleImageSelect,
                      )
                    }
                  >
                    <FontAwesomeIcon title="Delete" icon={solid('times')} />
                  </Button>
                </InputGroup>
                {values.cartItems[itemIndex][name].preview && (
                  <div className="">
                    <Image
                      src={values.cartItems[itemIndex][name].preview}
                      className="border-0 w-100 mt-2"
                      thumbnail
                    />
                  </div>
                )}
              </Form.Group>
            )}
          </>
        );
      }}
    </Field>
  );
}

export default ImageInput;
