import { ErrorMessage } from 'formik';
import { Form, InputGroup, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

function TextInput({
  fieldName,
  label,
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  userRef,
  type,
  disabled,
  readOnly,
  plaintext,
  size,
  reset,
  resetDisabled,
  resetClick,
  hideLabel,
}) {
  return (
    <Form.Group as={Col} controlId={fieldName} className="mb-3">
      {!hideLabel && <Form.Label className="label">{label}</Form.Label>}
      <InputGroup hasValidation>
        <Form.Control
          type={type ?? 'text'}
          aria-describedby="inputGroupPrepend"
          name={fieldName}
          onChange={handleChange}
          value={values[fieldName]}
          size={size ?? 'lg'}
          isInvalid={touched[fieldName] && !!errors[fieldName]}
          onBlur={handleBlur}
          ref={userRef ?? null}
          disabled={disabled ?? false}
          readOnly={readOnly ?? false}
          plaintext={plaintext ?? false}
        />
        {reset && (
          <Button
            onClick={resetClick}
            title="Reset field"
            disabled={resetDisabled}
          >
            <FontAwesomeIcon icon={solid('rotate-left')} title="Reset" />
          </Button>
        )}
        <ErrorMessage
          component="div"
          name={fieldName}
          className="invalid-feedback"
        />
      </InputGroup>
    </Form.Group>
  );
}

export default TextInput;
