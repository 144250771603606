import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import { AUTH_LEVEL_ADMIN } from "../../constants";

const AdminAuth = () => {
  const { auth } = useAuth();
  const location = useLocation();

  return auth?.user?.authLevel === AUTH_LEVEL_ADMIN ? (
    <Outlet />
  ) : (
    <Navigate to="unauthorized" state={{ from: location }} replace />
  );
};

export default AdminAuth;
