import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AUTH_COOKIE_NAME } from '../../constants';
import Cookies from 'js-cookie';

const RequireAuth = () => {
  const location = useLocation();
  let cookie = {};

  if (Cookies.get(AUTH_COOKIE_NAME)) {
    cookie = JSON.parse(Cookies.get(AUTH_COOKIE_NAME));
  }

  return cookie?.token ? (
    <Outlet />
  ) : (
    <Navigate to="login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
