class SellerService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getSellers = (input, page, perPage) => {
    let params = {};
    if (input) params['search'] = input;
    if (page) params['page'] = page;
    if (perPage) params['perPage'] = perPage;

    return this.api.get({
      location: '/seller',
      ...(Object.keys(params).length !== 0 && { params: params }),
    });
  };

  getSeller = (id) => {
    return this.api.get({
      location: `/seller/${id}`,
    });
  };

  createSeller = (input) => {
    return this.api.post({
      location: `/seller/create`,
      body: input,
    });
  };

  deleteSeller = (input) => {
    return this.api.post({
      location: '/seller/delete',
      body: { id: input },
    });
  };

  updateSeller = (input) => {
    return this.api.put({
      location: `/seller/${input.id}/update`,
      body: input,
    });
  };
}

export default SellerService;
