import React from 'react';
import './App.scss';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import Layout from './Components/Layout';
import AdminAuth from './Components/Auth/AdminAuth';
import Login from './Components/Auth/Login';
import ForgotPassword from './Components/Auth/ForgotPassword';
import UpdatePassword from './Components/Auth/UpdatePassword';
import ResetPassword from './Components/Auth/ResetPassword';
import RequireAuth from './Components/Auth/RequireAuth';
import Receipts from './Components/Receipt/Receipts';
import AddReceipt from './Components/Receipt/AddReceipt';
import Sellers from './Components/Seller/Sellers';
import AddSeller from './Components/Seller/AddSeller';
import SellerProfile from './Components/Seller/SellerProfile';
import Users from './Components/User/Users';
import AddUser from './Components/User/AddUser';
import UserProfile from './Components/User/UserProfile';
import ReceiptProfile from './Components/Receipt/ReceiptProfile';
import Calculator from './Components/Calculator/Calculator';
import SalesReport from './Components/Reports/SalesReport';
import Professions from './Components/Professions/Professions';
import AddProfession from './Components/Professions/AddProfession';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public routes */}
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<>Unauthorized</>} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="update-password" element={<UpdatePassword />} />
        <Route path="reset-password" element={<ResetPassword />} />

        {/* Private routes */}
        <Route element={<RequireAuth />}>
          <Route path="/" element={<Navigate replace to="receipts" />} />
          <Route path="receipts" element={<Receipts />} />
          <Route path="receipt/:id" element={<ReceiptProfile />} />
          <Route path="add-receipt" element={<AddReceipt />} />
          <Route path="add-receipt/:sellerId" element={<AddReceipt />} />
          <Route path="sellers" element={<Sellers />} />
          <Route path="seller/:id" exact element={<SellerProfile />} />
          <Route path="add-seller" element={<AddSeller />} />
          <Route path="calculator" element={<Calculator />} />
          <Route path="*" element={<Navigate to="/" replace />} />

          {/* Admin routes */}
          <Route element={<AdminAuth />}>
            <Route path="admin" element={<Outlet />}>
              <Route path="users" element={<Users />} />
              <Route path="user/:id" index element={<UserProfile />} />
              <Route path="add-user" element={<AddUser />} />
              <Route path="sales-report" element={<SalesReport />} />
              <Route path="professions" element={<Professions />} />
              <Route path="add-profession" element={<AddProfession />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
