import React from 'react';
import { Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import getUrlString from '../Helpers/getUrlString';

const ListPagination = ({
  baseUrl,
  searchTerm = '',
  pagination,
  startDate,
  endDate,
}) => {
  const pageButtons = [];
  let maxPages = 3;
  let startIndex = 1;
  let endIndex = 3;

  if (pagination.totalPages <= maxPages) {
    endIndex = pagination.totalPages;
    maxPages = endIndex;
  } else if (pagination.page < maxPages) {
    startIndex = 1;
    endIndex = maxPages;
  } else if (
    pagination.page >= 2 &&
    pagination.page <= pagination.totalPages - 1
  ) {
    startIndex = pagination.page - 1;
    if (pagination.page + 1 < pagination.totalPages) {
      endIndex = pagination.page + 1;
    } else {
      endIndex = pagination.totalPages;
    }
  } else {
    endIndex = pagination.totalPages;
    startIndex = endIndex - 2;
  }

  for (let page = startIndex; page <= endIndex; page += 1) {
    pageButtons.push(
      <Pagination.Item
        key={`page${page}`}
        active={pagination.page === page}
        href={getUrlString(
          baseUrl,
          searchTerm,
          page,
          pagination.perPage,
          startDate,
          endDate,
        )}
      >
        {page}
      </Pagination.Item>,
    );
  }

  return (
    <div className="d-flex">
      <Pagination className="mt-5 mx-auto">
        <Pagination.First
          href={getUrlString(
            baseUrl,
            searchTerm,
            1,
            pagination.perPage,
            startDate,
            endDate,
          )}
          disabled={pagination.page === 1}
        >
          <FontAwesomeIcon icon={solid('angles-left')} title="First" />
        </Pagination.First>

        <Pagination.Prev
          href={getUrlString(
            baseUrl,
            searchTerm,
            pagination.page - 1,
            pagination.perPage,
            startDate,
            endDate,
          )}
          disabled={pagination.page === 1}
        >
          <FontAwesomeIcon icon={solid('angle-left')} title="Previous" />
        </Pagination.Prev>

        {pageButtons}

        <Pagination.Next
          href={getUrlString(
            baseUrl,
            searchTerm,
            pagination.page + 1,
            pagination.perPage,
            startDate,
            endDate,
          )}
          disabled={pagination.page === pagination.totalPages}
        >
          <FontAwesomeIcon icon={solid('angle-right')} title="Next" />
        </Pagination.Next>

        <Pagination.Last
          disabled={pagination.page === pagination.totalPages}
          href={getUrlString(
            baseUrl,
            searchTerm,
            pagination.totalPages,
            pagination.perPage,
            startDate,
            endDate,
          )}
        >
          <FontAwesomeIcon icon={solid('angles-right')} title="Last" />
        </Pagination.Last>
      </Pagination>
    </div>
  );
};

export default ListPagination;
