import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import currency from 'currency.js';

function ConfirmReceipt({
  show,
  onClose,
  onSubmit,
  confirmedItems,
  metals,
  receiptTotal,
}) {
  const metalsArr = [].concat(
    ...Object.keys(metals).map((v) => {
      metals[v].map((n) => (n.category = v));
      return metals[v];
    }),
  );

  return (
    <>
      <Modal show={show} onHide={onClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {confirmedItems.length > 0 ? (
              <>
                <Row className="result-list__header-row border-top-0 g-2">
                  <Col xs="auto">#</Col>
                  <Col className="d-none d-md-block">Category</Col>
                  <Col xs="4">Type</Col>
                  <Col className="text-end">lbs</Col>
                  <Col className="d-none d-md-block text-end">$/lb</Col>
                  <Col className="text-end">Ttl</Col>
                </Row>
                <div className="result-list">
                  {confirmedItems.map((item, index) => {
                    const metalCategory = item
                      ? metalsArr.find(
                          (metal) =>
                            parseInt(metal.metalsCategoryId) ===
                            parseInt(item.metalsCategoryId),
                        ).category
                      : '';
                    const metalType = item
                      ? metalsArr.find(
                          (metal) =>
                            parseInt(metal.id) === parseInt(item.metalTypeId),
                        )
                      : {};

                    return (
                      <Row key={item.itemId} className="result-list__row g-2">
                        <Col xs="auto">{index + 1}</Col>
                        <Col className="d-none d-md-block">{metalCategory}</Col>
                        <Col xs="4">{metalType.type}</Col>
                        <Col className="text-end">{item.weight}</Col>
                        <Col className="d-none d-md-block text-end">
                          {currency(metalType.price).format()}
                        </Col>
                        <Col className="text-end">
                          {currency(metalType.price * item.weight).format()}
                        </Col>
                      </Row>
                    );
                  })}
                  <Row className="g-2">
                    <Col className="text-end py-3 border-top">
                      <strong>Total:&nbsp;&nbsp;{receiptTotal}</strong>
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              'No receipt items'
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Submit Receipt
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmReceipt;
