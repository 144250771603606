import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const UserFormChangeObserver = ({ user, setFormHasChanged }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    const formEquality = (input) => {
      return (
        user.username === input.username &&
        user.email === input.email &&
        user.authLevel === input.authLevel
      );
    };
    setFormHasChanged(!formEquality(values));
  }, [user.email, user.username, user.authLevel, setFormHasChanged, values]);

  return null;
};

export default UserFormChangeObserver;
