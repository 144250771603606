import React from 'react';
import { Alert, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const AlertErrors = ({ show, message }) => (
  <Alert variant="danger" className="text-danger" show={show}>
    <Row>
      <Col xs="auto">
        <FontAwesomeIcon
          title="Error"
          icon={solid('circle-exclamation')}
          className="me-2"
        />
      </Col>
      <Col>
        <ul className="m-0 p-0">
          {message?.map((m, i) => (
            <li key={`${m}-${i}`} className="d-block">
              {m}
            </li>
          ))}
        </ul>
      </Col>
    </Row>
  </Alert>
);

export default AlertErrors;
