import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  InputGroup,
} from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import { catchApiResponseError } from '../../Helpers/catchApiResponseError';
import API from '../../Helpers/API';
import ReportService from '../../Helpers/ReportService';
import currency from 'currency.js';
import AlertErrors from '../AlertErrors';
import BackLinkTitle from '../BackLinkTitle';
import Spinner from '../Spinner';

function SalesReport() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [report, setReport] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [weightTotal, setWeightTotal] = useState(0);
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const loadReport = useCallback(async (sDate, eDate) => {
    try {
      setShowSpinner(true);
      const response = await new ReportService(new API()).getReport(
        sDate ? moment(sDate).startOf('day').format() : null,
        eDate ? moment(eDate).endOf('day').format() : null,
      );
      const metals = response.data;

      setGrandTotal(metals.reduce((a, b) => a + b.totalPrice, 0));
      setWeightTotal(metals.reduce((a, b) => a + b.totalWeight, 0));

      const categories = [
        ...new Set(metals.map((item) => item.category)),
      ].sort();
      const allMetals = categories.map((cat) => ({
        [cat]: metals.filter((item) => item.category === cat && item),
      }));

      setReport(allMetals);
      response && setShowSpinner(false);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors, {
        404: 'No report found',
      });
      setShowSpinner(false);
    }
  }, []);

  const handleSubmit = () => {
    if (!startDate && !endDate) navigate(0);

    const params = {
      startDate: startDate ? moment(startDate).startOf('day').format() : null,
      endDate: endDate ? moment(endDate).endOf('day').format() : null,
    };

    const queryString = Object.keys(params)
      .map((key) => params[key] && key + '=' + params[key])
      .filter(Boolean)
      .join('&');

    navigate(`/admin/sales-report?${queryString}`);
  };

  const clearDates = () => {
    searchParams.delete('startDate');
    searchParams.delete('endDate');
    setStartDate(null);
    setEndDate(null);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const sDate = searchParams.get('startDate');
    const eDate = searchParams.get('endDate');
    if (sDate) setStartDate(moment(sDate));
    if (eDate) setEndDate(moment(eDate));

    if (!sDate && !eDate) {
      const params = {
        startDate: moment().startOf('day').subtract(7, 'd').format(),
        endDate: moment().endOf('day').format(),
      };

      const queryString = Object.keys(params)
        .map((key) => params[key] && key + '=' + params[key])
        .filter(Boolean)
        .join('&');

      navigate(`/admin/sales-report?${queryString}`);
    }

    loadReport(sDate, eDate);
  }, [loadReport, navigate, searchParams]);

  return (
    <>
      <Container>
        <BackLinkTitle
          url="/receipts"
          srText="Back to receipts"
          title="Sales Report"
        />

        <Form.Group as={Col} lg className="mb-3">
          <Form.Label className="label">Date Filter</Form.Label>
          <InputGroup hasValidation>
            <DateRangePicker
              startDate={startDate}
              startDateId="startDate"
              endDate={endDate}
              endDateId="endDate"
              onDatesChange={handleDatesChange}
              focusedInput={focusedInput}
              minimumNights={0}
              onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
              isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
              numberOfMonths={1}
              readOnly
            />
            <Button
              onClick={clearDates}
              variant="link"
              className="btn-clear-search"
            >
              <FontAwesomeIcon icon={faXmark} />
              <span className="sr-only">Clear search</span>
            </Button>
            <Button onClick={handleSubmit}>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
              <span className="sr-only">Search</span>
            </Button>
          </InputGroup>
        </Form.Group>

        <AlertErrors show={showErrors} message={errorMessages} />

        <Row>
          <Col md="6" className="text-center mb-4">
            <h4>Grand Total Sales:</h4>
            <h4 className="text-primary">{currency(grandTotal).format()}</h4>
          </Col>
          <Col md="6" className="text-center mb-4">
            <h4>Grand Total Weight:</h4>
            <h4 className="text-primary">{weightTotal} lbs</h4>
          </Col>
        </Row>

        <Row className="justify-content-center">
          {report.map((category, index) => {
            const salesTotal = category[Object.keys(category)].reduce(
              (a, b) => a + b.totalPrice,
              0,
            );
            const weightTotal = category[Object.keys(category)].reduce(
              (a, b) => a + b.totalWeight,
              0,
            );

            return (
              <React.Fragment key={index}>
                <Col lg="6" className="mb-5">
                  <Card className="profilecard h-100">
                    <Card.Header className="profilecard__header">
                      <h3>{Object.keys(category)}</h3>
                    </Card.Header>

                    <Card.Body className="profilecard__body">
                      <Row className="result-list__header-row border-top-0 mx-0 align-items-end">
                        <Col xs="5">Metal/Material</Col>
                        <Col xs className="text-end">
                          Total Weight (lbs)
                        </Col>
                        <Col xs className="text-end">
                          Total Sales
                        </Col>
                      </Row>
                      {category[Object.keys(category)].map(
                        (item, itemIndex) => (
                          <Row
                            className="result-list__row mx-0"
                            key={itemIndex}
                          >
                            <Col xs="5">{item.type}</Col>
                            <Col xs className="text-end">
                              {item.totalWeight}
                            </Col>

                            <Col xs className="text-end">
                              {currency(item.totalPrice).format()}
                            </Col>
                          </Row>
                        ),
                      )}
                      <Row className="result-list__header-row mx-0 align-items-end text-primary">
                        <Col xs="5">Totals:</Col>
                        <Col xs className="text-end">
                          {weightTotal}
                        </Col>
                        <Col xs className="text-end">
                          {currency(salesTotal).format()}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </React.Fragment>
            );
          })}
        </Row>
      </Container>
      <Spinner show={showSpinner} />
    </>
  );
}

export default SalesReport;
