import { createContext, useState } from 'react';
import Cookies from 'js-cookie';
import { AUTH_COOKIE_NAME } from '../constants';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  if (Object.keys(auth).length === 0 && Cookies.get(AUTH_COOKIE_NAME)) {
    setAuth(JSON.parse(Cookies.get(AUTH_COOKIE_NAME)));
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
