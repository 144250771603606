import { useContext } from 'react';
import { Button, useAccordionButton, AccordionContext } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

function AccordionToggleButton({ children, eventKey, callback, disabled }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey.includes(eventKey);

  return (
    <Button
      onClick={decoratedOnClick}
      variant="light"
      className="d-flex align-items-center"
      disabled={disabled}
    >
      {children}
      <FontAwesomeIcon
        className="ms-2 text-secondary"
        icon={isCurrentEventKey ? solid('chevron-up') : solid('chevron-down')}
      />
    </Button>
  );
}

export default AccordionToggleButton;
