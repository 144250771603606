import moment from 'moment';

const getUrlString = (
  baseUrl,
  searchTerm,
  page,
  perPage,
  startDate,
  endDate,
) => {
  const params = {
    search: searchTerm ?? null,
    page: page ? parseInt(page) : null,
    perPage: perPage ? parseInt(perPage) : null,
    startDate: startDate ? moment(startDate).startOf('day').format() : null,
    endDate: endDate ? moment(endDate).endOf('day').format() : null,
  };

  const queryString = Object.keys(params)
    .map((key) => params[key] && key + '=' + params[key])
    .filter(Boolean)
    .join('&');

  return `${baseUrl}${queryString ? `?${queryString}` : ''}`;
};

export default getUrlString;
