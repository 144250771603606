import * as yup from 'yup';
import { userNameRegExp, phoneRegExp } from '../regEx';
import { SUPPORTED_IMAGE_FORMATS, MAX_FILE_SIZE } from '../../constants';

const emailValidation = yup
  .string()
  .email('Invalid email')
  .required('Required');

const usernameValidation = yup
  .string()
  .trim()
  .min(4, 'The username must be at minimum 4 characters')
  .matches(userNameRegExp, 'Invalid characters')
  .required('Required');

const imageValidation = yup
  .mixed()
  .test('FILE_EMPTY', 'Required', (value) => {
    return !value || value?.file?.size;
  })
  .test(
    'FILE_FORMAT',
    'Uploaded file has unsupported format.',
    (value) =>
      !value ||
      (value?.file?.type && SUPPORTED_IMAGE_FORMATS.includes(value.file.type)),
  )
  .test('FILE_SIZE', 'Max file size is 5 MB', (value) => {
    return !value || value?.file?.size <= MAX_FILE_SIZE;
  });

const loginValidation = yup.object().shape({
  username: yup.string().trim().required('Please enter your username'),
  password: yup.string().required('Please enter your password'),
});

const forgotPasswordValidation = yup.object().shape({
  usernameEmail: yup.string().trim().required('Required'),
});

const passwordResetValidation = yup.object().shape({
  username: yup.string().trim().required(),
});

const sellerValidation = yup.object().shape({
  name: yup.string().required('Required'),
  email: emailValidation,
  phone: yup
    .string()
    .required('Required')
    .matches(phoneRegExp, 'Invalid phone number'),
  profession: yup.string().required('Required'),
});

const userValidation = yup.object().shape({
  username: usernameValidation,
  email: emailValidation,
  emailConfirm: yup
    .string()
    .oneOf([yup.ref('email'), null], 'Emails must match')
    .required('Required'),
  authLevel: yup.string().required('Required'),
});

const userUpdateValidation = yup.object().shape({
  username: usernameValidation,
  email: emailValidation,
  authLevel: yup.string().required('Required'),
});

const receiptValidation = yup.object().shape({
  seller: yup.object().shape({
    value: yup.string().required(),
    label: yup.string().required(),
  }),
  cartItems: yup.array().of(
    yup.object().shape({
      metalsCategory: yup.string().required('Required'),
      metalType: yup.string().required('Required'),
      weight: yup
        .number()
        .typeError('Weight must be a positive integer')
        .required('Required')
        .positive('Weight must be a positive integer')
        .min(yup.ref('tare'), 'Weight must be more than or equal to item tare'),
      tare: yup
        .number()
        .required('Required')
        .max(yup.ref('weight'), 'Tare weight must be less than item weight'),
      imageMetal: imageValidation,
      imageWeight: imageValidation,
    }),
  ),
});

const resetPasswordValidation = yup.object().shape({
  newPassword: yup.string().required('Required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Required'),
});

const updatePasswordValidation = yup.object().shape({
  oldPassword: yup.string().required('Required'),
  newPassword: yup.string().required('Required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Required'),
});

const professionValidation = yup.object().shape({
  profession: yup.string().trim().required('Required'),
});

export {
  loginValidation,
  forgotPasswordValidation,
  passwordResetValidation,
  sellerValidation,
  userValidation,
  userUpdateValidation,
  receiptValidation,
  resetPasswordValidation,
  updatePasswordValidation,
  professionValidation,
  emailValidation,
};
