class ReportService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getReport = (startDate, endDate) => {
    let params = {};
    if (startDate) params['startDate'] = startDate;
    if (endDate) params['endDate'] = endDate;

    return this.api.get({
      location: 'reports/sales-report',
      ...(Object.keys(params).length !== 0 && { params: params }),
    });
  };
}

export default ReportService;
