import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { Form as FormikForm, Formik } from 'formik';
import { Container, Row, Col, Table } from 'react-bootstrap';
import API from '../../Helpers/API';
import UserService from '../../Helpers/UserService';
import AlertErrors from '../AlertErrors';
import AlertSuccess from '../AlertSuccess';
import ListPagination from '../ListPagination';
import SearchInput from '../SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { catchApiResponseError } from '../../Helpers/catchApiResponseError';
import Spinner from '../Spinner';

function Users() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [users, setUsers] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [searchField, setSearchField] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  const doSearch = async (input, page, perPage) => {
    try {
      setShowSpinner(true);
      const response = await new UserService(new API()).getUsers(
        input.userSearch,
        page,
        perPage,
      );
      setUsers(response.data);
      setFormSubmitted(true);
      response && setShowSpinner(false);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
      setShowSpinner(false);
    }
  };

  const handleSubmit = (input) => {
    navigate(input.userSearch ? `/admin/users?search=${input.userSearch}` : 0);
  };

  useEffect(() => {
    const search = searchParams.get('search');
    const page = searchParams.get('page');
    const perPage = searchParams.get('perPage');

    if (search) setSearchField(search);

    doSearch({ userSearch: search }, page, perPage);

    const deletedUser = sessionStorage.getItem('userDeleted');
    if (deletedUser) {
      setSuccessMessage(`User: ${deletedUser} successfully deleted`);
      setShowSuccess(true);
      sessionStorage.removeItem('userDeleted');
    }
  }, [searchParams]);

  const clearSearch = () => {
    setSearchField('');
    searchParams.delete('search');
    setSearchParams(searchParams);
  };

  return (
    <>
      <Container className="mt-4">
        <Row className="align-items-baseline mb-4">
          <Col>
            <h1>Users</h1>
          </Col>
          <Col xs="auto">
            <Link to="/admin/add-user" className="btn btn-primary">
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Add New User
            </Link>
          </Col>
        </Row>

        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={{ userSearch: searchField }}
        >
          {({
            handleSubmit,
            handleChange,
            handleReset,
            values,
            touched,
            errors,
          }) => (
            <>
              <FormikForm onSubmit={handleSubmit}>
                <SearchInput
                  label="Search by username, email or auth level"
                  name="userSearch"
                  handleChange={handleChange}
                  values={values}
                  handleClear={() => {
                    handleReset();
                    clearSearch();
                  }}
                  handleSubmit={handleSubmit}
                />
              </FormikForm>

              <AlertErrors show={showErrors} message={errorMessages} />

              <AlertSuccess
                show={showSuccess}
                message={successMessage}
                setShowAlert={setShowSuccess}
              />

              {users.total > 0 && (
                <>
                  <div className="label w-100 text-end pb-3">
                    {users.total} Result{users.total > 1 ? 's' : ''}:
                  </div>
                  <Row className="d-none d-lg-flex result-list__header-row d-print-none">
                    <Col lg="5">Username</Col>
                    <Col lg="4">Email</Col>
                    <Col lg="3">Auth Level</Col>
                  </Row>
                  <div className="result-list d-print-none">
                    {users.data.map((user) => (
                      <Row
                        key={user.id}
                        className="result-list__row word-break-all position-relative"
                      >
                        <Col
                          xs="12"
                          lg="5"
                          className="fw-bold text-wrap result-list__name"
                        >
                          <Link to={`/admin/user/${user.id}`} key={user.id}>
                            {user.username}
                          </Link>
                        </Col>
                        <Col xs="12" lg="4" className="text-wrap">
                          {user.email}
                        </Col>
                        <Col
                          xs="12"
                          lg="3"
                          className="text-wrap text-capitalize"
                        >
                          {user.authLevel}
                        </Col>
                      </Row>
                    ))}
                  </div>

                  <Table striped className="d-none d-print-table">
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Auth Level</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users?.data?.map((user) => (
                        <tr key={user.id}>
                          <td>{user?.username}</td>
                          <td>{user?.email}</td>
                          <td>{user?.authLevel}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
              {users.total > 0 && (
                <ListPagination
                  baseUrl={`users`}
                  searchTerm={values.userSearch}
                  pagination={users}
                />
              )}
            </>
          )}
        </Formik>

        {users.length < 1 && formSubmitted && (
          <p className="lead">No results</p>
        )}
      </Container>
      <Spinner show={showSpinner} />
    </>
  );
}

export default Users;
