class AuthService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  login = (input) => {
    return this.api.post({
      location: 'login',
      body: input,
    });
  };

  passwordRequest = (input) => {
    return this.api.post({
      location: '/request-reset-password',
      body: input,
    });
  };

  updatePassword = (input) => {
    return this.api.post({
      location: '/update-password',
      body: input,
    });
  };

  resetPassword = (input) => {
    return this.api.post({
      location: '/reset-password',
      body: input,
    });
  };
}

export default AuthService;
