import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { catchApiResponseError } from '../../Helpers/catchApiResponseError';
import API from '../../Helpers/API';
import ReceiptService from '../../Helpers/ReceiptService';
import currency from 'currency.js';
import AlertErrors from '../AlertErrors';
import BackLinkTitle from '../BackLinkTitle';
import Spinner from '../Spinner';

function Calculator() {
  const [metals, setMetals] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  const loadMetals = useCallback(async () => {
    try {
      setShowSpinner(true);
      const response = await new ReceiptService(new API()).getMetals();
      setMetals(response.data);
      response && setShowSpinner(false);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors, {
        404: 'No prices found',
      });
      setShowSpinner(false);
    }
  }, []);

  useEffect(() => {
    loadMetals();
  }, [loadMetals]);

  return (
    <>
      <Container>
        <BackLinkTitle
          url="/receipts"
          srText="Back to receipts"
          title="Calculator/Current Prices"
        />

        <AlertErrors show={showErrors} message={errorMessages} />

        <Row className="justify-content-center">
          {Object.keys(metals)?.map((category, index) => (
            <React.Fragment key={index}>
              <Col lg="6" className="mb-5">
                <Card className="profilecard h-100">
                  <Card.Header className="profilecard__header">
                    <h3>{category}</h3>
                  </Card.Header>
                  <Card.Body className="profilecard__body">
                    <Row className="result-list__header-row border-top-0 mx-0">
                      <Col>Metal/Material</Col>
                      <Col className="text-end">Current Price</Col>
                    </Row>
                    {metals[category].map((item) => (
                      <React.Fragment key={item.id}>
                        <Row className="result-list__row mx-0">
                          <Col xs="auto">{item.type}</Col>
                          <Col className="text-end">
                            {item.price ? (
                              `${currency(item.price).format()}/lbs`
                            ) : (
                              <a href="tel:347-206-4296">Call for price</a>
                            )}
                          </Col>
                        </Row>
                      </React.Fragment>
                    ))}
                  </Card.Body>
                </Card>
              </Col>
            </React.Fragment>
          ))}
        </Row>
      </Container>
      <Spinner show={showSpinner} />
    </>
  );
}

export default Calculator;
