import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form as FormikForm, Formik } from 'formik';
import { Button, Col, Container, Row } from 'react-bootstrap';
import API from '../../Helpers/API';
import SellerService from '../../Helpers/SellerService';
import ProfessionsService from '../../Helpers/ProfessionsService';
import { sellerValidation } from '../../Helpers/ValidationSchemas/validation';
import { sanitizePhone } from '../../Helpers/phoneHelpers';
import ModalConfirm from '../ModalConfirm';
import AlertErrors from '../AlertErrors';
import TextInput from '../TextInput';
import ProfessionsDropdown from '../ProfessionsDropdown';
import PhoneInput from '../PhoneInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import BackLinkTitle from '../BackLinkTitle';
import { catchApiResponseError } from '../../Helpers/catchApiResponseError';
import Spinner from '../Spinner';

function NewSeller() {
  const navigate = useNavigate();
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [professions, setProfessions] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const resetMessages = () => {
    setShowErrors(false);
    setErrorMessages([]);
  };

  const getProfessions = useCallback(async () => {
    try {
      const response = await new ProfessionsService(new API()).getProfessions();
      setProfessions(response.data);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
    }
  }, []);

  const handleSubmit = async (input, { resetForm, validateForm }) => {
    try {
      setShowSpinner(true);
      resetMessages();
      const response = await new SellerService(new API()).createSeller({
        name: input.name,
        email: input.email,
        phoneNumber: sanitizePhone(input.phone),
        professionId: input.profession,
      });
      sessionStorage.setItem('sellerAdded', response.data.name);
      navigate(`/seller/${response.data.id}`);
      response && setShowSpinner(false);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
      setShowSpinner(false);
    }
  };

  const handleClear = async (resetForm, validateForm) => {
    setErrorMessages([]);
    await resetForm();
    validateForm();
  };

  useEffect(() => {
    getProfessions();
  }, [getProfessions]);

  return (
    <>
      <Container>
        <div className="d-flex justify-content-center mb-5">
          <div className="mxw-400 w-100">
            <BackLinkTitle
              url="/sellers"
              srText="Back to sellers"
              title="Add New Seller"
            />

            <AlertErrors show={showErrors} message={errorMessages} />

            <Formik
              enableReinitialize
              onSubmit={handleSubmit}
              initialValues={{
                name: '',
                email: '',
                phone: '',
                profession: '',
              }}
              validationSchema={sellerValidation}
              validateOnMount
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                validateForm,
                resetForm,
                touched,
                isValid,
                values,
                errors,
              }) => (
                <FormikForm onSubmit={handleSubmit}>
                  <TextInput
                    fieldName="name"
                    label="Name"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                  />

                  <TextInput
                    fieldName="email"
                    label="Email"
                    type="email"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                  />

                  <PhoneInput
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                  />

                  <ProfessionsDropdown
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    professions={professions}
                    values={values}
                    touched={touched}
                    errors={errors}
                  />

                  <Row>
                    <Col xs="auto">
                      <Button
                        variant="primary"
                        disabled={!isValid || !values}
                        onClick={handleShow}
                      >
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        Add Seller
                      </Button>
                    </Col>
                    <Col xs="auto">
                      <Button
                        variant="outline-secondary"
                        onClick={() => handleClear(resetForm, validateForm)}
                      >
                        <FontAwesomeIcon icon={faXmark} className="me-2" />
                        Clear
                      </Button>
                    </Col>
                  </Row>
                  <ModalConfirm
                    show={showModal}
                    title="Confirm"
                    text="Are you sure you want to add this new seller?"
                    confirmButtonText="Add Seller"
                    confirmClick={handleSubmit}
                    handleClose={handleClose}
                  />
                </FormikForm>
              )}
            </Formik>
          </div>
        </div>
      </Container>
      <Spinner show={showSpinner} />
    </>
  );
}

export default NewSeller;
