import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { loginValidation } from '../../Helpers/ValidationSchemas/validation';
import API from '../../Helpers/API';
import AuthService from '../../Helpers/AuthService';
import useAuth from '../../Hooks/useAuth';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AUTH_COOKIE_NAME, AUTH_COOKIE_EXPIRY } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { catchApiResponseError } from '../../Helpers/catchApiResponseError';
import TextInput from '../TextInput';
import AlertErrors from '../AlertErrors';
import AlertSuccess from '../AlertSuccess';
import Spinner from '../Spinner';

function Login() {
  const { setAuth } = useAuth();
  const userRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const resetMessages = () => {
    setShowErrors(false);
    setErrorMessages([]);
  };

  const handleSubmit = async (input) => {
    try {
      setShowSpinner(true);
      resetMessages();
      const response = await new AuthService(new API(false)).login({
        username: input.username,
        password: input.password,
      });

      const data = response.data;
      setAuth(data);
      Cookies.set(AUTH_COOKIE_NAME, JSON.stringify(data), {
        expires: AUTH_COOKIE_EXPIRY,
      });
      navigate(from, { replace: true });
      setShowSpinner(false);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors, {
        401: 'Login failed',
      });
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    userRef.current.focus();

    const resetPassword = sessionStorage.getItem('passwordReset');
    const updatePassword = sessionStorage.getItem('passwordUpdate');
    if (resetPassword || updatePassword) {
      setSuccessMessage(
        `You have successfully ${resetPassword ? 'reset' : ''}${
          updatePassword ? 'updated' : ''
        } your password. Please login.`,
      );
      setShowSuccess(true);
      resetPassword && sessionStorage.removeItem('passwordReset');
      updatePassword && sessionStorage.removeItem('passwordUpdate');
    }
  }, []);

  return (
    <>
      <Container fluid>
        <div className="d-flex justify-content-center mb-5">
          <div className="mxw-400 w-100">
            <AlertSuccess
              show={showSuccess}
              setShowAlert={setShowSuccess}
              message={successMessage}
              autoHide={false}
            />

            <Formik
              validationSchema={loginValidation}
              onSubmit={handleSubmit}
              initialValues={{
                username: '',
                password: '',
              }}
              validateOnMount
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isValid,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <TextInput
                    fieldName="username"
                    label="Username"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    userRef={userRef}
                  />
                  <TextInput
                    fieldName="password"
                    label="Password"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    type="password"
                  />
                  <Button
                    type="submit"
                    size="lg"
                    className="px-5 mt-3 mb-4"
                    disabled={!isValid}
                  >
                    <FontAwesomeIcon icon={faLock} />
                    &nbsp;&nbsp;Log In
                  </Button>
                </Form>
              )}
            </Formik>

            <div className="mnh-60">
              <AlertErrors show={showErrors} message={errorMessages} />
            </div>

            <Link to="/forgot-password" className="mt-3 d-inline-block">
              Forgot password?
            </Link>
          </div>
        </div>
      </Container>
      <Spinner show={showSpinner} />
    </>
  );
}

export default Login;
