import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { saveAs } from 'save-files';
import printJS from 'print-js';
import { Container, Button, Row, Col, Card, ListGroup } from 'react-bootstrap';
import moment from 'moment';
import API from '../../Helpers/API';
import ReceiptService from '../../Helpers/ReceiptService';
import isLoggedIn from '../../Helpers/LoggedIn';
import { AUTH_LEVEL_ADMIN } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { catchApiResponseError } from '../../Helpers/catchApiResponseError';
import currency from 'currency.js';
import AlertSuccess from '../AlertSuccess';
import AlertErrors from '../AlertErrors';
import ModalConfirm from '../ModalConfirm';
import BackLinkTitle from '../BackLinkTitle';
import Spinner from '../Spinner';
import ReceiptItem from './ReceiptItem';

function ReceiptProfile() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [receipt, setReceipt] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showEmailConfirm, setShowEmailConfirm] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showVoidConfirm, setShowVoidConfirm] = useState(false);
  const [voided, setVoided] = useState(false);
  const [isAdmin] = useState(isLoggedIn() === AUTH_LEVEL_ADMIN);
  const [showImageId, setShowImageId] = useState();

  const getImage = useCallback(async (itemId) => {
    try {
      const response = await new ReceiptService(new API()).getImage(itemId);
      let item = response.data;
      item.itemId = itemId;
      return await item;
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
    }
  }, []);

  const getReceipt = useCallback(async () => {
    try {
      setShowSpinner(true);
      const response = await new ReceiptService(new API()).getReceipt(id);
      const receipt = response.data;

      await Promise.all(
        receipt.invoice_item.map(async (item) => {
          item['images'] = await getImage(item.id);
        }),
      );

      setReceipt(receipt);
      if (receipt.voidedByUserId) setVoided(true);
      response && setShowSpinner(false);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
      setShowSpinner(false);
    }
  }, [getImage, id]);

  const downloadReceipt = async (print = false) => {
    try {
      setShowSpinner(true);
      const response = await new ReceiptService(
        new API(true, 'application/json', 'blob'),
      ).downloadReceipt(id);
      const blob = new Blob([response.data], { type: 'application/pdf' });

      if (print) {
        printJS(URL.createObjectURL(blob));
      } else {
        saveAs(
          blob,
          `${String(receipt?.id).padStart(5, '0')}-Receipt-OnLuckTrading${
            receipt?.voidedByUserId ? '-voided' : ''
          }.pdf`,
        );
      }
      response && setShowSpinner(false);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
      setShowSpinner(false);
    }
  };

  const emailReceipt = async () => {
    try {
      setShowSpinner(true);
      const response = await new ReceiptService(new API()).emailReceipt(id);
      if (response.status === 200) {
        setSuccessMessage(
          `Email has been sent to ${receipt.seller.name} at ${receipt.seller.email}`,
        );
        setShowSuccess(true);
      }
      response && setShowSpinner(false);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
      setShowSpinner(false);
    }
  };

  const voidReceipt = async () => {
    try {
      setShowSpinner(true);
      const response = await new ReceiptService(new API()).voidReceipt(id);
      sessionStorage.setItem('receiptVoided', receipt.id);
      response && setShowSpinner(false);
      navigate(0);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    getReceipt();

    const receiptVoided = sessionStorage.getItem('receiptVoided');
    if (receiptVoided) {
      setSuccessMessage(
        `Receipt #: ${String(receiptVoided).padStart(
          5,
          '0',
        )} successfully voided`,
      );
      setShowSuccess(true);
      sessionStorage.removeItem('receiptVoided');
    }

    const receiptAdded = sessionStorage.getItem('receiptAdded');
    if (receiptAdded) {
      setSuccessMessage(
        `Receipt #: ${String(receiptAdded).padStart(
          5,
          '0',
        )} successfully added`,
      );
      setShowSuccess(true);
      sessionStorage.removeItem('receiptAdded');
    }
  }, [getReceipt]);

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col>
            <AlertSuccess
              show={showSuccess}
              setShowAlert={setShowSuccess}
              message={successMessage}
            />
            <AlertErrors show={showErrors} message={errorMessages} />
            {receipt?.invoice_item && (
              <Card className="profilecard">
                <Card.Header className="profilecard__header">
                  <ListGroup variant="flush" className="profilecard__listgroup">
                    <ListGroup.Item className="profilecard__listgroup-item position-relative justify-content-between align-items-center">
                      <div
                        className={`profilecard__header-wrapper ${
                          voided ? 'text-danger' : ''
                        }`}
                      >
                        <BackLinkTitle
                          url="/receipts"
                          srText="Back to receipts"
                          noMargin
                          title={`Receipt #: ${String(receipt?.id).padStart(
                            5,
                            '0',
                          )}`}
                        />
                        {voided && <div className="void-stamp">VOID</div>}
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Header>

                <Card.Body className="profilecard__body">
                  <ListGroup variant="flush" className="profilecard__listgroup">
                    <ListGroup.Item className="profilecard__listgroup-item">
                      <FontAwesomeIcon
                        icon={solid('calendar')}
                        className="profilecard__listgroup-icon"
                      />
                      <p className="mb-0">
                        <strong>Date:&emsp;</strong>
                        {receipt.created_at
                          ? moment(receipt.created_at).format('L') +
                            ' ' +
                            moment(receipt.created_at).format('LT')
                          : ''}
                      </p>
                    </ListGroup.Item>
                    <ListGroup.Item className="profilecard__listgroup-item">
                      <FontAwesomeIcon
                        icon={solid('user')}
                        className="profilecard__listgroup-icon"
                      />

                      <p className="mb-0">
                        <strong>Seller:&emsp;</strong>
                        {receipt?.seller?.name}
                      </p>
                    </ListGroup.Item>
                    <ListGroup.Item className="profilecard__listgroup-item">
                      <FontAwesomeIcon
                        icon={solid('user-pen')}
                        className="profilecard__listgroup-icon"
                      />

                      <p className="mb-0">
                        <strong>Created by:&emsp;</strong>
                        {receipt?.user?.username}
                      </p>
                    </ListGroup.Item>
                    {receipt?.voided_by?.username && (
                      <>
                        <ListGroup.Item className="profilecard__listgroup-item">
                          <FontAwesomeIcon
                            icon={solid('ban')}
                            className="profilecard__listgroup-icon text-danger"
                          />

                          <p className="mb-0 text-danger">
                            <strong>Voided by:&emsp;</strong>
                            {receipt.voided_by.username}
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item className="profilecard__listgroup-item">
                          <FontAwesomeIcon
                            icon={solid('ban')}
                            className="profilecard__listgroup-icon text-danger"
                          />

                          <p className="mb-0 text-danger">
                            <strong>Void Date:&emsp;</strong>
                            {moment(receipt?.deleted_at).format('L') +
                              ' ' +
                              moment(receipt?.deleted_at).format('LT')}
                          </p>
                        </ListGroup.Item>
                      </>
                    )}
                    <ListGroup.Item className="px-0">
                      {receipt?.invoice_item && (
                        <>
                          <div className="text-end p-2 mb-2 bg-warning bg-opacity-10">
                            Total Payout:&emsp;&emsp;
                            {currency(receipt.total).format()}
                          </div>
                          <div className="result-list d-print-none">
                            {receipt.invoice_item.map((item, index) => (
                              <ReceiptItem
                                key={item.itemId}
                                id={item.itemId}
                                index={index}
                                category={item.metals_category.category}
                                type={item.metal_type.type}
                                weight={item.weight}
                                tare={item.tareWeight}
                                price={`${currency(item.price).format()} / lb`}
                                total={currency(
                                  item.price * item.weight,
                                ).format()}
                                imageMetal={`data:image/png;base64, ${item.images.imageMetal}`}
                                imageWeight={`data:image/png;base64, ${item.images.imageWeight}`}
                                showImageId={showImageId}
                                setShowImageId={setShowImageId}
                              />
                            ))}
                            <div className="text-end p-2 bg-warning bg-opacity-10">
                              Total Payout:&emsp;&emsp;
                              {currency(receipt.total).format()}
                            </div>
                          </div>
                        </>
                      )}
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>

                <Card.Footer className="p-0">
                  <Row className="g-0 d-print-none">
                    <Col xs="12" md>
                      <Button
                        className="profilecard__btn"
                        onClick={() => downloadReceipt()}
                      >
                        <FontAwesomeIcon
                          icon={solid('download')}
                          className="profilecard__btn-icon"
                        />
                        Download
                      </Button>
                    </Col>
                    <Col xs="12" md>
                      <Button
                        variant="outline-primary"
                        className="profilecard__btn"
                        onClick={() => downloadReceipt(true)}
                      >
                        <FontAwesomeIcon
                          icon={solid('print')}
                          className="profilecard__btn-icon"
                        />
                        Print
                      </Button>
                    </Col>
                    {!voided && (
                      <>
                        <Col xs="12" md>
                          <Button
                            className="profilecard__btn"
                            variant="secondary"
                            onClick={() => setShowEmailConfirm(true)}
                          >
                            <FontAwesomeIcon
                              icon={solid('envelope')}
                              className="profilecard__btn-icon"
                            />
                            Email
                          </Button>
                        </Col>

                        {isAdmin && (
                          <Col xs="12" md>
                            <Button
                              className="profilecard__btn"
                              variant="outline-secondary"
                              onClick={() => setShowVoidConfirm(true)}
                            >
                              <FontAwesomeIcon
                                icon={solid('ban')}
                                className="profilecard__btn-icon"
                              />
                              Void Receipt
                            </Button>
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                </Card.Footer>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
      <ModalConfirm
        show={showEmailConfirm}
        title="Confirm send receipt"
        text={`You are about to email ${receipt?.seller?.name} at ${receipt?.seller?.email}`}
        handleClose={() => setShowEmailConfirm(false)}
        confirmButtonText="Confirm"
        confirmClick={() => emailReceipt()}
      />
      <ModalConfirm
        show={showVoidConfirm}
        title="Confirm Void Receipt"
        text="You are about to void this receipt. This action is irreversible."
        handleClose={() => setShowVoidConfirm(false)}
        confirmButtonText="Confirm"
        confirmClick={() => voidReceipt()}
        doubleConfirm
      />
      <Spinner show={showSpinner} />
    </>
  );
}

export default ReceiptProfile;
