class UserService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getUsers = (input, page, perPage) => {
    let params = {};
    if (input) params['search'] = input;
    if (page) params['page'] = page;
    if (perPage) params['perPage'] = perPage;

    return this.api.get({
      location: '/user',
      ...(Object.keys(params).length !== 0 && { params: params }),
    });
  };

  getUser = (id) => {
    return this.api.get({
      location: `/user/${id}`,
    });
  };

  createUser = (input) => {
    return this.api.post({
      location: `/user/create`,
      body: input,
    });
  };

  deleteUser = (input) => {
    return this.api.post({
      location: '/user/delete',
      body: { id: input },
    });
  };

  updateUser = (input) => {
    return this.api.put({
      location: `/user/${input.id}/update`,
      body: input,
    });
  };
}

export default UserService;
