import { useEffect, useState, useCallback } from 'react';
import { Button, Container, Row, Col, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import API from '../../Helpers/API';
import ProfessionsService from '../../Helpers/ProfessionsService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import EditProfession from './EditProfession';
import AlertSuccess from '../AlertSuccess';
import AlertErrors from '../AlertErrors';
import Spinner from '../Spinner';
import { catchApiResponseError } from '../../Helpers/catchApiResponseError';

function Professions() {
  const [professions, setProfessions] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [showSpinner, setShowSpinner] = useState(false);

  const getProfessions = useCallback(async () => {
    try {
      setShowSpinner(true);
      const response = await new ProfessionsService(new API()).getProfessions();
      setProfessions(response.data);
      response && setShowSpinner(false);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
      setShowSpinner(false);
    }
  }, []);

  useEffect(() => {
    getProfessions();

    const professionAdded = sessionStorage.getItem('professionAdded');
    if (professionAdded) {
      setSuccessMessage(
        `New profession: ${professionAdded} successfully added`,
      );
      setShowSuccess(true);
      sessionStorage.removeItem('professionAdded');
    }
  }, [getProfessions]);

  return (
    <>
      <Container className="mt-4">
        <Row className="align-items-baseline mb-5">
          <Col>
            <h1>Professions</h1>
          </Col>
          <Col xs="auto">
            <Link
              to="/admin/add-profession"
              className={`btn btn-primary ${
                editIndex || editIndex === 0 ? 'disabled' : ''
              }`}
              disabled={editIndex || editIndex === 0}
            >
              <FontAwesomeIcon icon={solid('plus')} className="me-2" />
              Add New Profession
            </Link>
          </Col>
        </Row>

        <AlertSuccess
          show={showSuccess}
          setShowAlert={setShowSuccess}
          message={successMessage}
        />
        <AlertErrors show={showErrors} message={errorMessages} />

        <Stack direction="horizontal" className="justify-content-end py-3">
          Total: {professions?.length}
        </Stack>

        <Row className="result-list__header-row">
          <Col>Profession</Col>
        </Row>
        {professions?.map((profession, index) => (
          <Row key={index} className="result-list__row">
            <Col xs="auto" className="pt-2">
              {String(index + 1).padStart(2, '0')}
            </Col>
            {editIndex === index ? (
              <Col>
                <EditProfession
                  profession={profession.profession}
                  setEditIndex={setEditIndex}
                  professionId={profession.id}
                  success={{ setSuccessMessage, setShowSuccess }}
                  errors={{ setErrorMessages, setShowErrors }}
                  getProfessions={getProfessions}
                />
              </Col>
            ) : (
              <>
                <Col className="text-capitalize align-items-center d-flex">
                  {profession.profession}
                </Col>
                <Col xs="auto">
                  <Button
                    variant="secondary"
                    onClick={() => setEditIndex(index)}
                    className={
                      !editIndex && editIndex !== 0 ? '' : 'invisible disabled'
                    }
                  >
                    <FontAwesomeIcon
                      title="edit"
                      icon={solid('pencil')}
                      className="me-2"
                    />
                    Edit
                  </Button>
                </Col>
              </>
            )}
          </Row>
        ))}
      </Container>
      <Spinner show={showSpinner} />
    </>
  );
}

export default Professions;
