import currency from 'currency.js';

function Total({ invoiceComplete, receiptTotal }) {
  return (
    <div className="text-end mb-3 lead">
      Total Payout:&nbsp;
      <span className={invoiceComplete ? 'text-success' : 'text-primary'}>
        {currency(receiptTotal).format()}
      </span>
    </div>
  );
}

export default Total;
