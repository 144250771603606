import { Table, Image } from 'react-bootstrap';

function ConfirmedItem({ values, getMetalPrice }) {
  return (
    <>
      <Table striped bordered className="d-lg-none">
        <tbody>
          <tr>
            <th>Category</th>
            <td>{values.metalsCategory}</td>
          </tr>
          <tr>
            <th>Type</th>
            <td>{values.metalType}</td>
          </tr>
          <tr>
            <th>$/lb</th>
            <td>{getMetalPrice(values.metalTypeId)}</td>
          </tr>
          <tr>
            <th>Weight</th>
            <td>{values.weight} lbs</td>
          </tr>
          <tr>
            <th>Tare</th>
            <td>{values.tare} lbs</td>
          </tr>
          <tr>
            <th>Net Weight</th>
            <td>{values.weight - values.tare} lbs</td>
          </tr>
          <tr>
            <th>Item Total</th>
            <td>
              {getMetalPrice(values.metalTypeId, values.weight - values.tare)}
            </td>
          </tr>
          <tr>
            <td>
              <p>Metal Photo:</p>
              <Image
                src={values.imageMetal.preview}
                className="border-0 w-100 mxw-100 d-block m-auto"
                thumbnail
              />
            </td>
            <td>
              <p>Weight Photo:</p>
              <Image
                src={values.imageWeight.preview}
                className="border-0 w-100 mxw-100 d-block m-auto"
                thumbnail
              />
            </td>
          </tr>
        </tbody>
      </Table>
      <Table striped bordered className="d-none d-lg-table">
        <thead>
          <tr>
            <th>Category:</th>
            <th>Type:</th>
            <th className="text-end">$/lb:</th>
            <th className="text-end">Weight:</th>
            <th className="text-end">Tare:</th>
            <th className="text-end">Net Weight:</th>
            <th className="text-end">Item Total:</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{values.metalsCategory}</td>
            <td>{values.metalType}</td>
            <td className="text-end">{getMetalPrice(values.metalTypeId)}</td>
            <td className="text-end">{values.weight} lbs</td>
            <td className="text-end">{values.tare} lbs</td>
            <td className="text-end">{values.weight - values.tare} lbs</td>
            <td className="text-end">
              {getMetalPrice(values.metalTypeId, values.weight - values.tare)}
            </td>
          </tr>
          <tr>
            <td className="border-end-0">Metal Photo:</td>
            <td className="border-start-0" colSpan={2}>
              <Image
                src={values.imageMetal.preview}
                className="mxw-150 m-auto d-block"
                thumbnail
              />
            </td>
            <td className="border-end-0">Weight Photo:</td>
            <td className="border-start-0" colSpan={3}>
              <Image
                src={values.imageWeight.preview}
                className="mxw-150 m-auto d-block"
                thumbnail
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

export default ConfirmedItem;
