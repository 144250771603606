import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import {
  emailValidation,
  forgotPasswordValidation,
} from '../../Helpers/ValidationSchemas/validation';
import API from '../../Helpers/API';
import AuthService from '../../Helpers/AuthService';
import useAuth from '../../Hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { catchApiResponseError } from '../../Helpers/catchApiResponseError';
import AlertSuccess from '../AlertSuccess';
import AlertErrors from '../AlertErrors';
import BackLinkTitle from '../BackLinkTitle';
import Spinner from '../Spinner';
import TextInput from '../TextInput';

function ForgotPassword() {
  const { setAuth } = useAuth();
  const userRef = useRef();
  const [feedback, setFeedback] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  const handleSubmit = async (input) => {
    try {
      setShowSpinner(true);
      setShowErrors(false);
      const isEmail = await emailValidation.isValid(input.usernameEmail);
      const response = await new AuthService(new API(false)).passwordRequest(
        isEmail
          ? { email: input.usernameEmail }
          : { username: input.usernameEmail },
      );
      setAuth(response.data);
      if (response) {
        setFeedback(
          'Your request has been submitted. If your username exists, a password reset will be sent to the associated email address.',
        );
        setShowSuccess(true);
        setShowSpinner(false);
      }
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors, {
        401: 'Unauthorized',
      });
      setShowSpinner(false);
    }
  };

  return (
    <>
      <Container fluid>
        <div className="d-flex justify-content-center mb-5">
          <div className="mxw-400 w-100">
            <BackLinkTitle
              url="/login"
              srText="Back to login"
              title="Password Request"
            />

            <Formik
              validationSchema={forgotPasswordValidation}
              onSubmit={handleSubmit}
              initialValues={{ usernameEmail: '' }}
              validateOnMount
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                resetForm,
                validateForm,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <TextInput
                    fieldName="usernameEmail"
                    label="Username or Email"
                    userRef={userRef}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                  />

                  <Button
                    type="submit"
                    size="lg"
                    className="my-4 px-5"
                    disabled={!isValid}
                  >
                    <FontAwesomeIcon icon={faLock} />
                    &nbsp;&nbsp;Submit Request
                  </Button>

                  <AlertSuccess
                    message={feedback}
                    show={showSuccess}
                    setShowAlert={setShowSuccess}
                  />
                  <AlertErrors show={showErrors} message={errorMessages} />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
      <Spinner show={showSpinner} />
    </>
  );
}

export default ForgotPassword;
