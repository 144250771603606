import { Col, Form } from 'react-bootstrap';
import { Field } from 'formik';

function NetWeight({ name, label, fieldName, weight, tare, labelRight }) {
  return (
    <Field name={fieldName}>
      {({ field }) => {
        return (
          <>
            <Form.Group
              as={Col}
              md={4}
              controlId={field}
              className="position-relative"
            >
              <>
                <Form.Label className="label d-flex justify-content-between">
                  <div>Net Weight (lbs)</div>
                  {labelRight && <strong>{labelRight}</strong>}
                </Form.Label>
                <Form.Control
                  {...field}
                  type="tel"
                  className="mb-4"
                  readOnly
                  value={
                    parseInt(weight ? weight : 0) - parseInt(tare ? tare : 0)
                  }
                  size="lg"
                  placeholder="0"
                />
              </>
            </Form.Group>
          </>
        );
      }}
    </Field>
  );
}

export default NetWeight;
