import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { Form as FormikForm, Formik } from 'formik';
import { Container, Row, Col, Table } from 'react-bootstrap';
import API from '../../Helpers/API';
import SellerService from '../../Helpers/SellerService';
import AlertErrors from '../AlertErrors';
import AlertSuccess from '../AlertSuccess';
import ListPagination from '../ListPagination';
import SearchInput from '../SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { plaintextPhone } from '../../Helpers/phoneHelpers';
import { catchApiResponseError } from '../../Helpers/catchApiResponseError';
import Spinner from '../Spinner';

function Sellers() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sellers, setSellers] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [searchField, setSearchField] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  const doSearch = async (input, page, perPage) => {
    try {
      setShowSpinner(true);
      const response = await new SellerService(new API()).getSellers(
        input.sellerSearch,
        page,
        perPage,
      );
      setSellers(response.data);
      setFormSubmitted(true);
      response && setShowSpinner(false);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
      setShowSpinner(false);
    }
  };

  const handleSubmit = (input) => {
    navigate(input.sellerSearch ? `/sellers?search=${input.sellerSearch}` : 0);
  };

  useEffect(() => {
    const search = searchParams.get('search');
    const page = searchParams.get('page');
    const perPage = searchParams.get('perPage');
    if (search) setSearchField(search);

    doSearch({ sellerSearch: search }, page, perPage);

    const deletedSeller = sessionStorage.getItem('sellerDeleted');
    if (deletedSeller) {
      setSuccessMessage(`User: ${deletedSeller} successfully deleted`);
      setShowSuccess(true);
      sessionStorage.removeItem('sellerDeleted');
    }
  }, [searchParams]);

  const clearSearch = () => {
    setSearchField('');
    searchParams.delete('search');
    setSearchParams(searchParams);
  };

  return (
    <>
      <Container className="mt-4">
        <Row className="align-items-baseline mb-4">
          <Col>
            <h1>Sellers</h1>
          </Col>
          <Col xs="auto">
            <Link to="/add-seller" className="btn btn-primary">
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Add New Seller
            </Link>
          </Col>
        </Row>

        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={{
            sellerSearch: searchField,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleReset,
            values,
            touched,
            errors,
          }) => (
            <>
              <FormikForm onSubmit={handleSubmit}>
                <SearchInput
                  label="Search by name, email or phone number"
                  name="sellerSearch"
                  handleChange={handleChange}
                  values={values}
                  handleClear={() => {
                    handleReset();
                    clearSearch();
                  }}
                  handleSubmit={handleSubmit}
                />
              </FormikForm>

              <AlertErrors show={showErrors} message={errorMessages} />

              <AlertSuccess
                show={showSuccess}
                message={successMessage}
                setShowAlert={setShowSuccess}
              />

              {sellers.total > 0 && (
                <>
                  <div className="label w-100 text-end pb-3">
                    {sellers.total} Result{sellers.total > 1 ? 's' : ''}:
                  </div>
                  <Row className="d-none d-lg-flex result-list__header-row d-print-none">
                    <Col lg="4">Name</Col>
                    <Col lg="4">Email</Col>
                    <Col lg="2">Phone</Col>
                    <Col lg="2">Profession</Col>
                  </Row>
                  <div className="result-list d-print-none">
                    {sellers.data.map((seller) => (
                      <Row
                        key={seller.id}
                        className="result-list__row word-break-all position-relative"
                      >
                        <Col
                          xs="12"
                          lg="4"
                          className="fw-bold text-wrap result-list__name"
                        >
                          <Link to={`/seller/${seller.id}`} className="fw-bold">
                            {seller?.name}
                          </Link>
                        </Col>
                        <Col xs="12" lg="4" className="text-wrap">
                          {seller?.email}
                        </Col>
                        <Col xs="12" lg="2" className="text-wrap">
                          {plaintextPhone(seller?.phoneNumber)}
                        </Col>
                        <Col
                          xs="12"
                          lg="2"
                          className="text-capitalize text-wrap"
                        >
                          {seller?.profession?.profession}
                        </Col>
                      </Row>
                    ))}
                  </div>

                  <Table striped className="d-none d-print-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Profession</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sellers?.data?.map((seller) => (
                        <tr key={seller.id}>
                          <td>{seller?.name}</td>
                          <td>{seller?.email}</td>
                          <td>{plaintextPhone(seller?.phoneNumber)}</td>
                          <td>{seller?.profession?.profession}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}

              {sellers.total > 0 && (
                <ListPagination
                  baseUrl={`sellers`}
                  searchTerm={values.sellerSearch}
                  pagination={sellers}
                />
              )}
            </>
          )}
        </Formik>

        {sellers.total < 1 && formSubmitted && (
          <p className="lead">No results</p>
        )}
      </Container>
      <Spinner show={showSpinner} />
    </>
  );
}

export default Sellers;
