import { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Button, Row, Col, Card, ListGroup } from 'react-bootstrap';
import API from '../../Helpers/API';
import UserService from '../../Helpers/UserService';
import AuthService from '../../Helpers/AuthService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { catchApiResponseError } from '../../Helpers/catchApiResponseError';
import EditUserProfile from './EditUserProfile';
import AlertSuccess from '../AlertSuccess';
import AlertErrors from '../AlertErrors';
import ModalConfirm from '../ModalConfirm';
import BackLinkTitle from '../BackLinkTitle';
import Spinner from '../Spinner';

function UserProfile() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState({ username: '', email: '', authLevel: '' });
  const [edit, setEdit] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [showResetConfirm, setShowResetConfirm] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const getUser = useCallback(async () => {
    try {
      setShowSpinner(true);
      const response = await new UserService(new API()).getUser(id);
      setUser(response.data);
      response && setShowSpinner(false);
    } catch (err) {
      catchApiResponseError(
        err,
        setErrorMessages,
        setShowErrors,
        { 404: '/admin/users' },
        navigate,
      );

      setShowSpinner(false);
    }
  }, [id, navigate]);

  const deleteUser = async () => {
    try {
      setShowSpinner(true);
      const response = await new UserService(new API()).deleteUser(id);
      if (response.status > 199 && response.status < 300) {
        sessionStorage.setItem('userDeleted', user.username);
        navigate('/admin/users');
      }
      response && setShowSpinner(false);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
    }
  };

  const requestPasswordReset = async () => {
    try {
      setShowSpinner(true);
      const response = await new AuthService(new API(false)).passwordRequest({
        email: user.email,
      });
      if (response) {
        setSuccessMessage(
          `Your request has been submitted. The password reset email will be sent to ${user.email}.`,
        );
        setShowSuccess(true);
      }
      response && setShowSpinner(false);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    getUser();

    const addedUser = sessionStorage.getItem('userAdded');
    if (addedUser) {
      setSuccessMessage(`${addedUser} has been added as a user`);
      setShowSuccess(true);
      sessionStorage.removeItem('userAdded');
    }
  }, [getUser]);

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col lg="6">
            <BackLinkTitle
              url="/admin/users"
              srText="Back to users"
              title="User Profile"
            />
            <AlertSuccess
              show={showSuccess}
              setShowAlert={setShowSuccess}
              message={successMessage}
            />
            <AlertErrors show={showErrors} message={errorMessages} />

            {edit ? (
              <EditUserProfile
                user={user}
                setEdit={setEdit}
                setSuccessMessage={setSuccessMessage}
                setShowSuccess={setShowSuccess}
                getUser={getUser}
                setShowSpinner={setShowSpinner}
              />
            ) : (
              <Card className="profilecard">
                <Card.Header className="profilecard__header">
                  <ListGroup variant="flush" className="profilecard__listgroup">
                    <ListGroup.Item className="profilecard__listgroup-item position-relative justify-content-between align-items-center">
                      <div className="profilecard__header-wrapper">
                        <FontAwesomeIcon
                          icon={faUser}
                          title="user"
                          className="profilecard__header-icon"
                        />
                        <h1>{user.username ?? ''}</h1>
                      </div>
                      <Button
                        className="profilecard__btn profilecard__btn--edit"
                        onClick={() => setEdit(true)}
                        disabled={!user.id}
                      >
                        <FontAwesomeIcon
                          icon={solid('pencil')}
                          title="edit profile"
                          className="profilecard__btn-icon m-0"
                        />
                      </Button>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Header>

                <Card.Body className="profilecard__body">
                  <ListGroup variant="flush" className="profilecard__listgroup">
                    <ListGroup.Item className="profilecard__listgroup-item">
                      <FontAwesomeIcon
                        icon={solid('envelope')}
                        title="envelope"
                        className="profilecard__listgroup-icon"
                      />

                      <p className="mb-0">
                        <strong>Email:&emsp;</strong>
                        <br className="d-md-none" />
                        {user.email ?? ''}
                      </p>
                    </ListGroup.Item>
                    <ListGroup.Item className="profilecard__listgroup-item">
                      <FontAwesomeIcon
                        icon={solid('toolbox')}
                        title="toolbox"
                        className="profilecard__listgroup-icon"
                      />

                      <p className="mb-0">
                        <strong>Auth Level:&emsp;</strong>
                        <br className="d-md-none" />
                        {user.authLevel ?? ''}
                      </p>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>

                <Card.Footer className="p-0 d-print-none">
                  <Button
                    className="profilecard__btn"
                    onClick={() => setShowResetConfirm(true)}
                    variant="outline-secondary"
                    disabled={!user.id}
                  >
                    <FontAwesomeIcon
                      icon={solid('key')}
                      className="profilecard__btn-icon"
                    />
                    Request Password Reset
                  </Button>
                  <Button
                    className="profilecard__btn"
                    onClick={() => setShowConfirm(true)}
                    disabled={!user.id}
                  >
                    <FontAwesomeIcon
                      icon={solid('ban')}
                      className="profilecard__btn-icon"
                    />
                    Delete User
                  </Button>
                </Card.Footer>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
      <ModalConfirm
        show={showConfirm}
        title="Confirm Delete"
        text="Are you sure you want to delete this user?"
        handleClose={() => setShowConfirm(false)}
        confirmButtonText="Confirm"
        confirmClick={() => deleteUser()}
        doubleConfirm
      />
      <ModalConfirm
        show={showResetConfirm}
        title="Confirm Password Reset"
        text={`Are you sure you want to trigger the password reset for ${user.email}?`}
        handleClose={() => setShowResetConfirm(false)}
        confirmButtonText="Confirm"
        confirmClick={() => requestPasswordReset()}
      />
      <Spinner show={showSpinner} />
    </>
  );
}

export default UserProfile;
