class ReceiptService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  addReceiptItem = (data) => {
    const sellerId = data.get('sellerId');
    return this.api.post({
      location: `seller/${sellerId}/receipt/add-item`,
      body: data,
    });
  };

  removeReceiptItem = (data) => {
    return this.api.post({
      location: `seller/${data.sellerId}/receipt/remove-item`,
      body: data,
    });
  };

  clearItems = (data) => {
    return this.api.post({
      location: `seller/${data.sellerId}/receipt/clear-items`,
      body: data,
    });
  };

  createReceipt = (data) => {
    return this.api.post({
      location: `seller/${data.sellerId}/receipt`,
      body: {
        sellerId: data.sellerId,
        userId: data.userId
      },
    });
  };

  getMetals = () => {
    return this.api.get({
      location: 'metals',
    });
  };

  getImages = (itemId) => {
    return this.api.get({
      location: `receipt-item/${itemId}/images`,
    });
  };

  getReceipts = (input, page, perPage, startDate, endDate, voided) => {
    let params = {};
    if (input) params['search'] = input;
    if (page) params['page'] = page;
    if (perPage) params['perPage'] = perPage;
    if (startDate) params['startDate'] = startDate;
    if (endDate) params['endDate'] = endDate;
    if (voided) params['voided'] = voided;

    return this.api.get({
      location: '/receipt',
      ...(Object.keys(params).length !== 0 && { params: params }),
    });
  };

  getReceipt = (id) => {
    return this.api.get({
      location: `receipt/${id}`,
    });
  };

  downloadReceipt = (id) => {
    return this.api.get({
      location: `receipt/${id}/download`,
      responseType: 'arraybuffer',
    });
  };

  emailReceipt = (id) => {
    return this.api.get({
      location: `receipt/${id}/email`,
    });
  };

  getImage = (id) => {
    return this.api.get({
      location: `receipt-item/${id}/images`,
    });
  };

  voidReceipt = (id) => {
    return this.api.post({
      location: `/receipt/void/${id}`,
    });
  };
}

export default ReceiptService;
