import { Modal, Button } from 'react-bootstrap';
import ConfirmedItem from './ConfirmedItem';

function ModalConfirmItem({
  show,
  handleClose,
  confirmClick,
  values,
  getMetalPrice,
}) {
  return (
    <Modal show={show} onHide={() => handleClose()} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Confirm Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You are about to add the following item:</p>
        <ConfirmedItem values={values} getMetalPrice={getMetalPrice} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            confirmClick();
            handleClose();
          }}
        >
          Confirm
        </Button>
        <Button variant="secondary" onClick={() => handleClose()}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalConfirmItem;
