import { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Button, Row, Col, Card, ListGroup } from 'react-bootstrap';
import API from '../../Helpers/API';
import SellerService from '../../Helpers/SellerService';
import { AUTH_LEVEL_ADMIN } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import EditSellerProfile from './EditSellerProfile';
import { plaintextPhone } from '../../Helpers/phoneHelpers';
import isLoggedIn from '../../Helpers/LoggedIn';
import AlertSuccess from '../AlertSuccess';
import AlertErrors from '../AlertErrors';
import ModalConfirm from '../ModalConfirm';
import BackLinkTitle from '../BackLinkTitle';
import { catchApiResponseError } from '../../Helpers/catchApiResponseError';
import Spinner from '../Spinner';

function SellerProfile() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [seller, setSeller] = useState({});
  const [edit, setEdit] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const getSeller = useCallback(async () => {
    try {
      setShowSpinner(true);
      const response = await new SellerService(new API()).getSeller(id);
      setSeller(response.data[0]);
      response && setShowSpinner(false);
    } catch (err) {
      catchApiResponseError(
        err,
        setErrorMessages,
        setShowErrors,
        { 404: '/sellers' },
        navigate,
      );

      setShowSpinner(false);
    }
  }, [id, navigate]);

  const deleteSeller = async () => {
    try {
      const response = await new SellerService(new API()).deleteSeller(id);
      if (response.status > 199 && response.status < 300) {
        sessionStorage.setItem('sellerDeleted', seller.name);
        navigate('/sellers');
      }
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
    }
  };

  useEffect(() => {
    getSeller();

    const addedSeller = sessionStorage.getItem('sellerAdded');
    if (addedSeller) {
      setSuccessMessage(`${addedSeller} has been added as a seller`);
      setShowSuccess(true);
      sessionStorage.removeItem('sellerAdded');
    }
  }, [getSeller]);

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col lg="6">
            <BackLinkTitle
              url="/sellers"
              srText="Back to sellers"
              title="Seller Profile"
            />
            <AlertSuccess
              show={showSuccess}
              setShowAlert={setShowSuccess}
              message={successMessage}
            />
            <AlertErrors show={showErrors} message={errorMessages} />

            {edit ? (
              <EditSellerProfile
                seller={seller}
                setEdit={setEdit}
                setSuccessMessage={setSuccessMessage}
                setShowSuccess={setShowSuccess}
                getSeller={getSeller}
                setShowSpinner={setShowSpinner}
              />
            ) : (
              <Card className="profilecard">
                <Card.Header className="profilecard__header">
                  <ListGroup variant="flush" className="profilecard__listgroup">
                    <ListGroup.Item className="profilecard__listgroup-item position-relative justify-content-between align-items-center">
                      <div className="profilecard__header-wrapper">
                        <FontAwesomeIcon
                          icon={faUser}
                          title="user"
                          className="profilecard__header-icon"
                        />
                        <h1>{seller.name ?? ''}</h1>
                      </div>
                      <Button
                        className="profilecard__btn profilecard__btn--edit"
                        onClick={() => setEdit(true)}
                      >
                        <FontAwesomeIcon
                          icon={solid('pencil')}
                          title="edit profile"
                          className="profilecard__btn-icon m-0"
                        />
                      </Button>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Header>

                <Card.Body className="profilecard__body">
                  <ListGroup variant="flush" className="profilecard__listgroup">
                    <ListGroup.Item className="profilecard__listgroup-item">
                      <FontAwesomeIcon
                        icon={solid('file')}
                        title="file"
                        className="profilecard__listgroup-icon"
                      />
                      <p className="mb-0">
                        <strong>Account:&emsp;</strong>
                        <br className="d-md-none" />
                        OLT-{String(seller.id).padStart(4, 0)}
                      </p>
                    </ListGroup.Item>
                    <ListGroup.Item className="profilecard__listgroup-item">
                      <FontAwesomeIcon
                        icon={solid('phone')}
                        title="phone"
                        className="profilecard__listgroup-icon"
                      />

                      <p className="mb-0">
                        <strong>Phone number:&emsp;</strong>
                        <br className="d-md-none" />
                        {seller.phoneNumber
                          ? plaintextPhone(seller.phoneNumber)
                          : ''}
                      </p>
                    </ListGroup.Item>
                    <ListGroup.Item className="profilecard__listgroup-item">
                      <FontAwesomeIcon
                        icon={solid('envelope')}
                        title="envelope"
                        className="profilecard__listgroup-icon"
                      />

                      <p className="mb-0">
                        <strong>Email:&emsp;</strong>
                        <br className="d-md-none" />
                        {seller.email ?? ''}
                      </p>
                    </ListGroup.Item>
                    <ListGroup.Item className="profilecard__listgroup-item">
                      <FontAwesomeIcon
                        icon={solid('toolbox')}
                        title="toolbox"
                        className="profilecard__listgroup-icon"
                      />

                      <p className="mb-0">
                        <strong>Profession:&emsp;</strong>
                        <br className="d-md-none" />
                        {seller?.profession?.profession}
                      </p>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>

                <Card.Footer className="p-0 d-print-none">
                  <Button
                    className="profilecard__btn"
                    onClick={() => navigate(`/add-receipt/${seller.id}`)}
                  >
                    <FontAwesomeIcon
                      icon={solid('plus')}
                      className="profilecard__btn-icon"
                    />
                    Add Receipt
                  </Button>

                  <Button
                    className="profilecard__btn"
                    variant="secondary"
                    onClick={() =>
                      navigate(`/receipts/?search=${seller.email}`)
                    }
                  >
                    <FontAwesomeIcon
                      icon={solid('receipt')}
                      className="profilecard__btn-icon"
                    />
                    View Receipts
                  </Button>

                  {isLoggedIn() === AUTH_LEVEL_ADMIN && (
                    <Button
                      className="profilecard__btn"
                      onClick={() => setShowConfirm(true)}
                      variant="outline-primary"
                    >
                      <FontAwesomeIcon
                        icon={solid('ban')}
                        className="profilecard__btn-icon"
                      />
                      Delete Seller
                    </Button>
                  )}
                </Card.Footer>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
      <ModalConfirm
        show={showConfirm}
        title="Confirm Delete"
        text="Are you sure you want to delete this seller?"
        handleClose={() => setShowConfirm(false)}
        confirmButtonText="Confirm"
        confirmClick={() => deleteSeller(id)}
        doubleConfirm
      />
      <Spinner show={showSpinner} />
    </>
  );
}

export default SellerProfile;
