import { ErrorMessage } from 'formik';
import { Form, InputGroup, Col } from 'react-bootstrap';

function AuthLevelDropdown({
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
}) {
  return (
    <Form.Group as={Col} controlId="profession" className="mb-3">
      <Form.Label className="label">Auth Level</Form.Label>
      <InputGroup hasValidation>
        <Form.Select
          name="authLevel"
          aria-label="Default select example"
          className="text-capitalize"
          size="lg"
          onChange={handleChange}
          value={values.authLevel}
          isInvalid={touched.authLevel && !!errors.authLevel}
          onBlur={handleBlur}
        >
          <option disabled value="">
            Select...
          </option>
          <option value="user">User</option>
          <option value="admin">Admin</option>
        </Form.Select>

        <ErrorMessage
          component="div"
          name="authLevel"
          className="invalid-feedback"
        />
      </InputGroup>
    </Form.Group>
  );
}

export default AuthLevelDropdown;
