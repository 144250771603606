import { Col, Form } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';

function SelectInput({
  name,
  label,
  fieldName,
  values,
  itemIndex,
  onChange,
  handleBlur,
  options,
  disabled,
  labelRight,
}) {
  return (
    <>
      <Field name={fieldName}>
        {({ field, form }) => {
          const value = values.cartItems[itemIndex][name];
          const isValid = !form.errors?.cartItems?.[itemIndex]?.[name];
          const isTouched = form.touched?.cartItems?.[itemIndex]?.[name];
          const isInvalid = isTouched && !isValid;

          return (
            <Form.Group
              as={Col}
              md={4}
              controlId={name}
              className="position-relative"
            >
              <>
                <Form.Label className="label d-flex justify-content-between">
                  <div>{label}</div>
                  {labelRight && <strong>{labelRight}</strong>}
                </Form.Label>
                <Form.Control
                  {...field}
                  as="select"
                  className="mb-4"
                  value={value}
                  isValid={isTouched && isValid}
                  isInvalid={isInvalid}
                  onChange={onChange}
                  disabled={disabled}
                  onBlur={handleBlur}
                  size="lg"
                >
                  <option disabled value="">
                    Select...
                  </option>
                  {options}
                </Form.Control>
                <ErrorMessage
                  component="div"
                  name={field.name}
                  className="invalid-feedback"
                />
              </>
            </Form.Group>
          );
        }}
      </Field>
    </>
  );
}

export default SelectInput;
