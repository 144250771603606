import { useState } from 'react';
import { Form as FormikForm, Formik } from 'formik';
import { Button, Card, ListGroup } from 'react-bootstrap';
import API from '../../Helpers/API';
import UserService from '../../Helpers/UserService';
import { userUpdateValidation } from '../../Helpers/ValidationSchemas/validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import AlertErrors from '../AlertErrors';
import UserFormChangeObserver from './UserFormChangeObserver';
import TextInput from '../TextInput';
import AuthLevelDropdown from './AuthLevelDropdown';
import ModalConfirm from '../ModalConfirm';
import { catchApiResponseError } from '../../Helpers/catchApiResponseError';

const EditUserProfile = ({
  user,
  setEdit,
  setShowSuccess,
  setSuccessMessage,
  getUser,
  setShowSpinner,
}) => {
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleSubmit = async (input) => {
    try {
      setShowSpinner(true);
      setShowErrors(false);
      setShowSuccess(false);
      const response = await new UserService(new API()).updateUser({
        id: user.id,
        username: input.username,
        email: input.email,
        authLevel: input.authLevel,
      });
      if (response.status > 199 && response.status < 300) {
        setSuccessMessage(`${response.data.username} updated`);
        setShowSuccess(true);
        getUser();
        setEdit(false);
        setShowSpinner(false);
      }
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
      setShowSpinner(false);
    }
  };

  return (
    <>
      <AlertErrors show={showErrors} message={errorMessages} />
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={{
          username: user.username ?? '',
          email: user.email ?? '',
          authLevel: user.authLevel ?? '',
        }}
        validationSchema={userUpdateValidation}
        validateOnMount
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          resetForm,
          touched,
          isValid,
          values,
          errors,
        }) => (
          <FormikForm onSubmit={handleSubmit}>
            <UserFormChangeObserver
              user={user}
              setFormHasChanged={setFormHasChanged}
            />
            <Card className="profilecard">
              <Card.Header className="profilecard__header">
                <ListGroup variant="flush" className="profilecard__listgroup">
                  <ListGroup.Item className="profilecard__listgroup-item position-relative justify-content-between align-items-center">
                    <div className="profilecard__header-wrapper">
                      <FontAwesomeIcon
                        icon={faUser}
                        title="user"
                        className="profilecard__header-icon"
                      />

                      <TextInput
                        fieldName="username"
                        label="Username"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                      />
                    </div>
                    <Button
                      className="profilecard__btn profilecard__btn--edit"
                      onClick={() => {
                        resetForm();
                        setFormHasChanged(false);
                      }}
                      title="Reset Form"
                      disabled={!formHasChanged}
                    >
                      <FontAwesomeIcon
                        icon={solid('rotate-left')}
                        title="Reset Form"
                        className="profilecard__btn-icon m-0"
                      />
                    </Button>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Header>

              <Card.Body className="profilecard__body">
                <ListGroup variant="flush" className="profilecard__listgroup">
                  <ListGroup.Item className="profilecard__listgroup-item">
                    <FontAwesomeIcon
                      icon={solid('envelope')}
                      title="envelope"
                      className="profilecard__listgroup-icon"
                    />

                    <TextInput
                      fieldName="email"
                      label="Email"
                      type="email"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="profilecard__listgroup-item">
                    <FontAwesomeIcon
                      icon={solid('toolbox')}
                      title="toolbox"
                      className="profilecard__listgroup-icon"
                    />

                    <AuthLevelDropdown
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      values={values}
                      touched={touched}
                      errors={errors}
                    />
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>

              <Card.Footer className="p-0">
                <Button
                  className="profilecard__btn"
                  onClick={() => setShowConfirm(true)}
                  disabled={!isValid || !formHasChanged}
                >
                  <FontAwesomeIcon
                    icon={solid('check')}
                    className="profilecard__btn-icon"
                  />
                  OK
                </Button>
                <Button
                  className="profilecard__btn"
                  variant="outline-primary"
                  onClick={() => setEdit(false)}
                >
                  <FontAwesomeIcon
                    icon={solid('ban')}
                    className="profilecard__btn-icon"
                  />
                  Cancel
                </Button>
              </Card.Footer>
            </Card>
            <ModalConfirm
              show={showConfirm}
              title="Confirm Update"
              text="Are you sure you want to update this user?"
              handleClose={() => setShowConfirm(false)}
              confirmButtonText="Confirm"
              confirmClick={handleSubmit}
            />
          </FormikForm>
        )}
      </Formik>
    </>
  );
};

export default EditUserProfile;
