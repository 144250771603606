import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { plaintextPhone } from '../../Helpers/phoneHelpers';

const SellerFormChangeObserver = ({ seller, setFormHasChanged }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    const formEquality = (input) => {
      return (
        seller.name === input.name &&
        seller.email === input.email &&
        plaintextPhone(seller.phoneNumber) === plaintextPhone(input.phone) &&
        parseInt(seller.professionId) === parseInt(input.profession)
      );
    };
    setFormHasChanged(!formEquality(values));
  }, [
    seller.email,
    seller.name,
    seller.phoneNumber,
    seller.professionId,
    setFormHasChanged,
    values,
  ]);

  return null;
};

export default SellerFormChangeObserver;
