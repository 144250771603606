class ProfessionsService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getProfessions = () => {
    return this.api.get({
      location: 'profession',
    });
  };

  addProfession = (input) => {
    return this.api.post({
      location: '/profession/add',
      body: input,
    });
  };

  deleteProfession = (input) => {
    return this.api.post({
      location: '/profession/delete',
      body: { id: input },
    });
  };

  updateProfession = (input) => {
    return this.api.put({
      location: `/profession/${input.id}/update`,
      body: input,
    });
  };
}

export default ProfessionsService;
