import { Table, Image } from 'react-bootstrap';
import Lightbox from 'react-awesome-lightbox';

function ReceiptItem({
  id,
  index,
  category = '',
  type = '',
  weight = '',
  tare = '',
  price = '',
  total = '',
  imageMetal = '',
  imageWeight = '',
  showImageId,
  setShowImageId = () => {},
}) {
  return (
    <>
      <Table striped bordered className="d-lg-none">
        <tbody>
          <tr>
            <th colSpan={2}>{String(index + 1).padStart(2, '0')}</th>
          </tr>
          <tr>
            <th className="w-50">Category</th>
            <td className="w-50">{category}</td>
          </tr>
          <tr>
            <th>Type</th>
            <td>{type}</td>
          </tr>
          <tr>
            <th>$/lb</th>
            <td>{price}</td>
          </tr>
          <tr>
            <th>Weight</th>
            <td>{weight} lbs</td>
          </tr>
          <tr>
            <th>Tare</th>
            <td>{tare} lbs</td>
          </tr>
          <tr>
            <th>Net Weight</th>
            <td>{weight - tare} lbs</td>
          </tr>
          <tr>
            <th>Item Total</th>
            <td>{total}</td>
          </tr>
          <tr>
            <td>
              <p>Metal Photo:</p>
              <img
                src={imageMetal}
                alt={`Item #${id} - ${type}`}
                className="thumbnail m-auto d-block"
                onClick={() => setShowImageId(`${id}M`)}
              />
              {showImageId === `${id}M` && (
                <Lightbox
                  image={imageMetal}
                  title={`Item #${id} - Metal - ${type}`}
                  onClose={() => setShowImageId()}
                />
              )}
            </td>
            <td>
              <p>Weight Photo:</p>
              <img
                src={imageWeight}
                alt={`Item #${id} - ${type}`}
                className="thumbnail m-auto d-block"
                onClick={() => setShowImageId(`${id}W`)}
              />
              {showImageId === `${id}W` && (
                <Lightbox
                  image={imageWeight}
                  title={`Item #${id} - Weight - ${type}`}
                  onClose={() => setShowImageId()}
                />
              )}
            </td>
          </tr>
        </tbody>
      </Table>

      <Table striped bordered className="d-none d-lg-table">
        <thead>
          <tr>
            <th className="w-20">{String(index + 1).padStart(2, '0')}</th>
            <th>Category</th>
            <th className="w-25">Type</th>
            <th className="text-end">$/lb</th>
            <th className="text-end">Weight</th>
            <th className="text-end">Tare</th>
            <th className="text-end">Net Weight</th>
            <th className="text-end">Item Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border-end-0"></td>
            <td className="border-start-0">{category}</td>
            <td>{type}</td>
            <td className="text-end">{price}</td>
            <td className="text-end">{weight} lbs</td>
            <td className="text-end">{tare} lbs</td>
            <td className="text-end">{weight - tare} lbs</td>
            <td className="text-end">{total}</td>
          </tr>
          <tr>
            <td className="border-end-0"></td>
            <td className="border-start-0 border-end-0 text-end">
              Metal Photo:
            </td>
            <td className="border-start-0 text-center" colSpan={2}>
              <img
                src={imageMetal}
                alt={`Item #${id} - ${type}`}
                className="thumbnail"
                onClick={() => setShowImageId(`${id}M`)}
              />
              {showImageId === `${id}M` && (
                <Lightbox
                  image={imageMetal}
                  title={`Item #${id} - Metal - ${type}`}
                  onClose={() => setShowImageId()}
                />
              )}
            </td>
            <td className="border-end-0 text-end">Weight Photo:</td>
            <td className="border-start-0 text-center" colSpan={3}>
              <Image
                src={imageWeight}
                className="thumbnail"
                thumbnail
                onClick={() => setShowImageId(`${id}W`)}
              />
              {showImageId === `${id}W` && (
                <Lightbox
                  image={imageWeight}
                  title={`Item #${id} - Weight - ${type}`}
                  onClose={() => setShowImageId()}
                />
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

export default ReceiptItem;
