import { ErrorMessage } from 'formik';
import { Form, InputGroup, Col } from 'react-bootstrap';

function ProfessionsDropdown({
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  professions,
}) {
  return (
    <Form.Group as={Col} controlId="profession" className="mb-5">
      <Form.Label className="label">Profession</Form.Label>
      <InputGroup hasValidation>
        <Form.Select
          name="profession"
          aria-label="Select the profession"
          className="text-capitalize"
          size="lg"
          onChange={handleChange}
          value={values.profession}
          isInvalid={touched.profession && !!errors.profession}
          onBlur={handleBlur}
        >
          <option disabled value="">
            Select...
          </option>
          {professions?.map((m) => (
            <option key={m.id} value={m.id}>
              {m.profession}
            </option>
          ))}
        </Form.Select>

        <ErrorMessage
          component="div"
          name="profession"
          className="invalid-feedback"
        />
      </InputGroup>
    </Form.Group>
  );
}

export default ProfessionsDropdown;
