import React from 'react';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

const AlertSuccess = ({ show, setShowAlert, message, autoHide = true }) => (
  <Alert
    show={show}
    variant="success"
    dismissible
    onAnimationEnd={() => setShowAlert(false)}
    onClose={() => setShowAlert(false)}
    className={show && autoHide ? 'animate' : ''}
  >
    <FontAwesomeIcon
      title="Success"
      icon={regular('circle-check')}
      className="me-2"
    />
    {message}
  </Alert>
);

export default AlertSuccess;
