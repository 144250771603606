import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form as FormikForm, Formik } from 'formik';
import { Button, Col, Container, Row } from 'react-bootstrap';
import API from '../../Helpers/API';
import UserService from '../../Helpers/UserService';
import { userValidation } from '../../Helpers/ValidationSchemas/validation';
import ModalConfirm from '../ModalConfirm';
import AlertErrors from '../AlertErrors';
import TextInput from '../TextInput';
import AuthLevelDropdown from './AuthLevelDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import BackLinkTitle from '../BackLinkTitle';
import { catchApiResponseError } from '../../Helpers/catchApiResponseError';
import Spinner from '../Spinner';

function NewUser() {
  const navigate = useNavigate();
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const resetMessages = () => {
    setShowErrors(false);
    setErrorMessages([]);
  };

  const handleSubmit = async (input, { resetForm, validateForm }) => {
    try {
      setShowSpinner(true);
      resetMessages();
      const response = await new UserService(new API()).createUser({
        username: input.username,
        email: input.email,
        email_confirmation: input.emailConfirm,
        authLevel: input.authLevel,
      });
      sessionStorage.setItem('userAdded', response.data.username);
      navigate(`/admin/user/${response.data.id}`);
      response && setShowSpinner(false);
    } catch (err) {
      catchApiResponseError(err, setErrorMessages, setShowErrors);
    }
  };

  const handleClear = async (resetForm, validateForm) => {
    setErrorMessages([]);
    await resetForm();
    validateForm();
  };

  return (
    <>
      <Container>
        <div className="d-flex justify-content-center mb-5">
          <div className="mxw-400 w-100">
            <BackLinkTitle
              url="/admin/users"
              srText="Back to users"
              title="Add New User"
            />

            <AlertErrors show={showErrors} message={errorMessages} />

            <Formik
              enableReinitialize
              onSubmit={handleSubmit}
              initialValues={{
                username: '',
                email: '',
                emailConfirm: '',
                authLevel: '',
              }}
              validationSchema={userValidation}
              validateOnMount
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                validateForm,
                resetForm,
                touched,
                isValid,
                values,
                errors,
              }) => (
                <FormikForm onSubmit={handleSubmit}>
                  <TextInput
                    fieldName="username"
                    label="Username"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                  />

                  <TextInput
                    fieldName="email"
                    label="Email"
                    type="email"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                  />

                  <TextInput
                    fieldName="emailConfirm"
                    label="Confirm Email"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                  />

                  <AuthLevelDropdown
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                  />

                  <Row>
                    <Col xs="auto">
                      <Button
                        variant="primary"
                        disabled={!isValid || !values}
                        onClick={handleShow}
                      >
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        Add User
                      </Button>
                    </Col>
                    <Col xs="auto">
                      <Button
                        variant="outline-secondary"
                        onClick={() => handleClear(resetForm, validateForm)}
                      >
                        <FontAwesomeIcon icon={faXmark} className="me-2" />
                        Clear
                      </Button>
                    </Col>
                  </Row>
                  <ModalConfirm
                    show={showModal}
                    title="Confirm"
                    text="Are you sure you want to add this new user?"
                    confirmButtonText="Add User"
                    confirmClick={handleSubmit}
                    handleClose={handleClose}
                  />
                </FormikForm>
              )}
            </Formik>
          </div>
        </div>
      </Container>
      <Spinner show={showSpinner} />
    </>
  );
}

export default NewUser;
