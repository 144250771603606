import { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

function ModalConfirm({
  show,
  title,
  text,
  handleClose,
  confirmButtonText,
  confirmClick,
  doubleConfirm,
}) {
  const [confirmChecked, setConfirmChecked] = useState(false);

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        setConfirmChecked(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{text}</div>
      </Modal.Body>
      <Modal.Footer>
        {doubleConfirm && (
          <Form.Check
            type="checkbox"
            id="confirmCheck"
            label="Check to confirm"
            onChange={() => setConfirmChecked(!confirmChecked)}
            value={confirmChecked}
          />
        )}
        <Button
          variant="primary"
          disabled={doubleConfirm ? !confirmChecked : false}
          onClick={() => {
            confirmClick();
            handleClose();
          }}
        >
          {confirmButtonText}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            handleClose();
            setConfirmChecked(false);
          }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalConfirm;
