import { Col, Form } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';
import { handleNumericInput } from '../../Helpers/normalize';

function WeightInput({
  name,
  label,
  fieldName,
  values,
  itemIndex,
  handleBlur,
  receipt,
  setFieldValue,
  labelRight,
}) {
  return (
    <Field name={fieldName}>
      {({ field, form }) => {
        const item = values.cartItems[itemIndex];
        const isValid = !form.errors?.cartItems?.[itemIndex]?.[name];
        const isTouched = form.touched?.cartItems?.[itemIndex]?.[name];
        const isInvalid = isTouched && !isValid;

        return (
          <>
            <Form.Group
              as={Col}
              md={4}
              controlId={field}
              className="position-relative"
            >
              <>
                <Form.Label className="label d-flex justify-content-between">
                  <div>{label}</div>
                  {labelRight && <strong>{labelRight}</strong>}
                </Form.Label>
                <Form.Control
                  {...field}
                  type="tel"
                  step={0.001}
                  className="mb-4"
                  isValid={isTouched && isValid}
                  isInvalid={isInvalid}
                  disabled={!item.metalType || !receipt.seller.value}
                  onBlur={handleBlur}
                  size="lg"
                  placeholder="0"
                  onChange={(e) =>
                    setFieldValue(fieldName, handleNumericInput(e.target.value))
                  }
                />
                <ErrorMessage
                  component="div"
                  name={field.name}
                  className="invalid-feedback"
                />
              </>
            </Form.Group>
          </>
        );
      }}
    </Field>
  );
}

export default WeightInput;
