import { Row, Col, Container, Navbar, Nav, NavLink } from 'react-bootstrap';
import { Outlet, useNavigate, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AUTH_COOKIE_NAME, AUTH_LEVEL_ADMIN } from '../constants';
import logo from './../Images/onluck-logo.png';
import isLoggedIn from './../Helpers/LoggedIn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState } from 'react';
import useAuth from '../Hooks/useAuth';

function Layout() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [isOpen, setMenu] = useState(false);
  const openMenu = () => setMenu((current) => !current);

  return (
    <main className="main">
      {/* styles, headers, footers, etc., i.e. global implementations in here */}
      {isLoggedIn() ? (
        <>
          <Container fluid className="nav__margin">
            <Navbar
              collapseOnSelect
              expand={false}
              fixed="top"
              className="nav__bar"
              onSelect={() => setMenu(false)}
            >
              <Container className="d-flex justify-content-between">
                <Link to="/" className="navbar-brand">
                  <img src={logo} alt="OnLuck Trading" className="nav__logo" />
                </Link>
                <Navbar.Toggle
                  aria-controls="mainNav"
                  className="nav__toggler border"
                  onClick={openMenu}
                >
                  {isOpen ? (
                    <FontAwesomeIcon
                      title="Receipts"
                      icon={solid('xmark')}
                      className="fs-1"
                    />
                  ) : (
                    <FontAwesomeIcon
                      title="Receipts"
                      icon={solid('bars')}
                      className="fs-1"
                    />
                  )}
                </Navbar.Toggle>
              </Container>
              <Navbar.Collapse id="mainNav" className="nav__collapse">
                <Nav activeKey="/" className="nav__nav">
                  <Nav.Item className="nav__item nav__bb text-white text-end p-2 bg-primary">
                    <FontAwesomeIcon
                      title="User"
                      icon={
                        isLoggedIn() === AUTH_LEVEL_ADMIN
                          ? solid('user-ninja')
                          : solid('user')
                      }
                      className="nav__icon"
                    />
                    {auth?.user?.username}
                  </Nav.Item>
                  <Nav.Item className="nav__item nav__bb">
                    <Nav.Link
                      as={Link}
                      eventKey={1}
                      to="/receipts"
                      className="nav__link"
                    >
                      <FontAwesomeIcon
                        title="Receipts"
                        icon={solid('receipt')}
                        className="nav__icon"
                      />
                      Receipts
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav__item nav__bb">
                    <Nav.Link
                      as={Link}
                      eventKey={2}
                      to="/sellers"
                      className="nav__link"
                    >
                      <FontAwesomeIcon
                        title="Sellers"
                        icon={solid('shop')}
                        className="nav__icon"
                      />
                      Sellers
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav__item nav__bb">
                    <Nav.Link
                      as={Link}
                      eventKey={3}
                      to="/calculator"
                      className="nav__link"
                    >
                      <FontAwesomeIcon
                        title="Calculator"
                        icon={solid('magnifying-glass-dollar')}
                        className="nav__icon"
                      />
                      Calculator/Current Prices
                    </Nav.Link>
                  </Nav.Item>

                  {isLoggedIn() === AUTH_LEVEL_ADMIN && (
                    <>
                      <Nav.Item className="nav__item nav__bb">
                        <Nav.Link
                          as={Link}
                          eventKey={4}
                          to="/admin/users"
                          className="nav__link"
                        >
                          <FontAwesomeIcon
                            title="Users"
                            icon={solid('users')}
                            className="nav__icon"
                          />
                          Users
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav__item nav__bb">
                        <Nav.Link
                          as={Link}
                          eventKey={5}
                          to="/admin/professions"
                          className="nav__link"
                        >
                          <FontAwesomeIcon
                            title="Professions"
                            icon={solid('user-tie')}
                            className="nav__icon"
                          />
                          Professions
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav__item nav__bb">
                        <Nav.Link
                          as={Link}
                          eventKey={6}
                          to="/admin/sales-report"
                          className="nav__link"
                        >
                          <FontAwesomeIcon
                            title="Reports"
                            icon={solid('book')}
                            className="nav__icon"
                          />
                          Sales Report
                        </Nav.Link>
                      </Nav.Item>
                    </>
                  )}
                  <Nav.Item className="nav__item nav__item--logout nav__bb">
                    <NavLink
                      onClick={() => {
                        Cookies.remove(AUTH_COOKIE_NAME);
                        setMenu(false);
                        navigate('login');
                      }}
                      className="nav__link"
                    >
                      <FontAwesomeIcon
                        title="users"
                        icon={solid('right-from-bracket')}
                        className="nav__icon"
                      />
                      Logout
                    </NavLink>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </>
      ) : (
        <Container fluid className="mt-4">
          <Row className="justify-content-center">
            <Col xs="auto">
              <Link to="/">
                <img src={logo} alt="OnLuck Trading" className="login-logo" />
              </Link>
            </Col>
          </Row>
        </Container>
      )}
      <Outlet />
    </main>
  );
}

export default Layout;
