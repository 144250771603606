import axios from 'axios';
import Cookies from 'js-cookie';
import { AUTH_COOKIE_NAME } from '../constants';

/**
 * Base abstraction for making an axios call to our servers
 * Provides some defaults such as headers and API base url.
 *
 * It is recommended to use this class with a 'Service' class that represents a set of calls
 * e.g. AuthService.login - has an internal reference to an API object and prepares a call
 *
 * Calls return axios objects, so they can be used the same as axios.
 */
class API {
  static base = process.env.REACT_APP_ONLUCKTRADING_API_URL;

  static defaultHeaders = {
    Accept: 'application/json',
  };

  _instance = null;

  static includeBearerToken = () => {
    const auth = JSON.parse(Cookies.get(AUTH_COOKIE_NAME));
    return (API.defaultHeaders = {
      ...API.defaultHeaders,
      ...{ Authorization: `Bearer ${auth.token}` },
    });
  };

  responseType = 'json';
  constructor(
    credentials = true,
    contentType = 'application/json',
    responseType = 'json',
  ) {
    API.defaultHeaders = {
      ...API.defaultHeaders,
      ...{ 'Content-Type': contentType },
    };

    if (credentials) {
      API.includeBearerToken();
    }

    this.responseType = responseType;

    this._instance = axios.create({
      baseURL: API.base,
      headers: API.defaultHeaders,
      responseType: this.responseType,
    });
  }

  get = ({ location, params }) => {
    return this._instance.get(location, { params });
  };

  post = ({ location, body, config }) => {
    return this._instance.post(location, body, config);
  };

  put = ({ location, body, config }) => {
    return this._instance.put(location, body, config);
  };

  patch = ({ location, body, config }) => {
    return this._instance.patch(location, body, config);
  };
}

export default API;
