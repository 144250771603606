const catchApiResponseError = (
  err,
  setErrorMessage,
  setShowErrors,
  redirect,
  navigate,
) => {
  if (!err?.response) {
    return ['No server response'];
  }

  let error = ['Unknown error'];

  if (err?.response?.data?.error) {
    error = [err.response.data.error];
  }

  if (err?.response?.data?.errors) {
    const errors = err.response.data.errors;
    if (errors.isArray) {
      error = Object.values(errors);
    } else {
      error = Object.keys(errors).map((key) => errors[key]) || [
        'Unknown error',
      ];
    }
  }

  const response = err.toJSON();
  const errorCode = response?.status;

  if (!isNaN(errorCode)) {
    if (redirect?.[errorCode]) {
      if (redirect?.[errorCode].indexOf('/') === 0) {
        navigate(redirect[errorCode]);
        return;
      } else {
        error = [redirect[errorCode]];
      }
    } else if (errorCode === 401) {
      navigate('/login');
      return;
    } else if (errorCode === 404) {
      error = ['Not found'];
    }
  }

  setErrorMessage(error);
  setShowErrors(true);
};

export { catchApiResponseError };
